import _ from 'lodash';

// @ts-expect-error [🤖 PLEASE FIX 🤖]: Parameter 'vehicleIds' implicitly has an 'any' type.
const hydrateVehicleIdsStateFromObject = (vehicleIds, compareActions) => {
  const { addToCompare } = compareActions;
  _.forEach(vehicleIds, (vehicleId) => {
    addToCompare(vehicleId);
  });
};

// @ts-expect-error [🤖 PLEASE FIX 🤖]: Parameter 'queryParams' implicitly has an 'any' type.
const hydrateVehicleIdsStateFromUrl = (queryParams, compareActions) => {
  const { vehicles } = queryParams;
  const vehicleIds = vehicles.split(',');

  hydrateVehicleIdsStateFromObject(vehicleIds, compareActions);
};

export { hydrateVehicleIdsStateFromObject, hydrateVehicleIdsStateFromUrl };
