import React from 'react';

import { COLORS } from '../../../constants';

// @ts-expect-error [🤖 PLEASE FIX 🤖]: Parameter 'props' implicitly has an 'any' type.
const DownloadIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" {...props}>
    <path
      fill={COLORS.GREY_ELEVEN}
      d="M18.333 12.467c.54 0 .978.437.978.977v4.89c0 .54-.438.977-.978.977H3.667a.978.978 0 0 1-.978-.978v-4.889a.978.978 0 0 1 1.955 0v3.912h12.712v-3.912c0-.54.437-.977.977-.977zM11 2.689c.54 0 .978.438.978.978v7.406l1.955-2.004c.382-.38 1-.38 1.381 0a.99.99 0 0 1 0 1.393l-3.666 3.667-.088.08a.77.77 0 0 1-.206.115.88.88 0 0 1-.757 0 .77.77 0 0 1-.294-.195L6.65 10.474a.99.99 0 0 1 0-1.393c.382-.38 1-.38 1.381 0l1.992 1.992V3.667c0-.54.438-.978.978-.978z"
    />
  </svg>
);

export default DownloadIcon;
