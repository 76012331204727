import React from 'react';
import styled from '@emotion/styled';

import { COLORS } from '../../../constants';

const StyledIcon = styled.svg`
  fill: ${COLORS.GREY_ELEVEN};
`;

// @ts-expect-error [🤖 PLEASE FIX 🤖]: Parameter 'props' implicitly has an 'any' type.
const LinkActiveIcon = (props) => (
  <StyledIcon xmlns="http://www.w3.org/2000/svg" width="20" height="18" viewBox="0 0 20 18" {...props}>
    <path d="M11.357 16.968a.78.78 0 0 1-.581-.264.81.81 0 0 1 .055-1.128l7.213-6.608L10.83 2.36a.81.81 0 0 1-.022-1.097.776.776 0 0 1 1.075-.087l7.857 7.2a.807.807 0 0 1 0 1.184l-7.857 7.2a.778.778 0 0 1-.527.208z" />
    <path d="M19.286 9.768H.714C.32 9.768 0 9.41 0 8.968c0-.442.32-.8.714-.8h18.572c.394 0 .714.358.714.8 0 .442-.32.8-.714.8z" />
  </StyledIcon>
);

export default LinkActiveIcon;
