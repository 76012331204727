import styled from '@emotion/styled';
import Grid from '@mui/material/Grid';

import { H5 } from '../type';
import RichText from '../rich-text';
import { BREAKPOINTS, SPACE } from '../../constants';
import { shouldForwardProp } from '../../utils/should-forward-prop';

const ContentGridWrapper = styled.div`
  padding-bottom: ${SPACE.X3};
  padding-top: ${SPACE.X3};
`;

const StyledGrid = styled(Grid, { shouldForwardProp })`
  justify-content: center;
  &.MuiGrid-root.MuiGrid-item {
    display: flex;
    justify-content: center;
    padding-bottom: 0;
    padding-top: 0;
  }
`;

const Image = styled.img`
  max-width: 100%;
`;

const ImageWrapper = styled.div`
  align-items: center;
  display: flex;
  margin-bottom: ${SPACE.X1};
`;

const ItemWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-bottom: ${SPACE.X2};

  @media (min-width: ${BREAKPOINTS.MD}px) {
    margin-bottom: none;
  }
`;

const ItemHeader = styled(H5)`
  margin-bottom: ${SPACE.X1};
  text-align: center;
`;

// @ts-expect-error [🤖 PLEASE FIX 🤖]: No overload matches this call.
const ItemBody = styled(RichText)`
  text-align: center;
  margin: 0;
`;

const Title = styled.div`
  margin-bottom: ${SPACE.X2};
  text-align: center;
`;

export { ContentGridWrapper, Image, ImageWrapper, ItemBody, ItemHeader, ItemWrapper, StyledGrid, Title };
