import _ from 'lodash';
import { YEARS_TO_SHOW, MAX_YEARS_TO_SHOW } from '@/components/similar-but-safer/constants';

// @ts-expect-error [🤖 PLEASE FIX 🤖]: Parameter 'vehicle' implicitly has an 'any' type.
export const buildQuery = (vehicle, vehicleSafetyFeaturesCount, ancapTestRecordRating) => {
  // Price query
  /* Rules:
   * - if the vehicle is < 10,000 the price range is 1-10,000
   * - if the vehicle is 10-15,000 the price bracket is -3000+
   * - if the vehicle is > 15,000 the price bracket is -5000+
   */
  let priceQuery = '';
  const priceLow = vehicle.privateMinPrice || vehicle.newPrice;
  const priceHigh = vehicle.privateMaxPrice || vehicle.newPrice;
  if (priceHigh <= 10000) {
    priceQuery = `maxPrice >= 1 AND minPrice <= 10000`;
  } else {
    const priceRangeBuffer = priceLow < 15000 ? 3000 : 5000;
    priceQuery = `maxPrice >= ${priceLow - priceRangeBuffer} AND minPrice <= ${priceHigh + priceRangeBuffer}`;
  }

  /* Rules:
   * - vehicle must be at least same year or 3 years newer.
   * - if the vehicle year is the same as the current year, we go back 1 year to try find more matches
   * - if the vehicle is > 15 years old, then recommend a vehicle that is newer than 15 years
   */
  const currentYear = new Date().getFullYear();
  const maxYearsAgo = currentYear - MAX_YEARS_TO_SHOW;
  const vehicleYearNum = _.toNumber(vehicle.year);
  const vehicleIsOlderThanMaxYear = vehicleYearNum <= maxYearsAgo;
  const vehicleYear = vehicleIsOlderThanMaxYear ? maxYearsAgo : vehicleYearNum;
  const minYear = vehicleIsOlderThanMaxYear ? vehicleYear : vehicleYear - YEARS_TO_SHOW;
  const maxYear = vehicleYear + YEARS_TO_SHOW;
  const yearQuery = `year > ${minYear} AND year < ${maxYear}`;

  // ANCAP specific query
  /* Rules:
   * - IF vehicle is ANCAP rated, vehicle must have been rated in the last 3 years. UCSR rated vehicles
   *   automatically pass.
   */
  const ancapSpecificQuery = `(hasRecentAncap:true OR ratingBy:UCSR)`;

  // Rating query
  /* Rules:
   * - Use UCSR rating instead of ancap if it exist.
   * - vehicles must have a higher rating than the current vehicle
   * - min 4* rating
   */
  const starRating = vehicle.ucsrRating || vehicle.ancapRating || ancapTestRecordRating;
  const ratingQuery = starRating > 4 ? `stars > 4` : `stars >= 4`;

  // Safety Feature query
  /* Rules:
   * - similar but safer vehicles must have the same or more safety features than the currently viewed vehicle
   */
  const safetyFeatureCount = (vehicleSafetyFeaturesCount && vehicleSafetyFeaturesCount.vehicle) || 0;
  const safetyFeatureQuery = `recommendedSafetyFeatures >= ${safetyFeatureCount}`;

  // Vehicle Size query
  /* Rules:
   * - If a vehicle is light|micro|small, recommend medium
   * - If a vehicle is large SUV, recommend medium SUV
   */
  let sizeQuery = `derivedBodyStyle:${vehicle.derivedBodyStyle}`;
  const isLight = vehicle.segment === 'Light' || vehicle.segment === 'Micro';
  const isPeopleMover = vehicle.derivedBodyStyle === 'peopleMovers';
  const isLargeSuv =
    (vehicle.segment === 'Large' || vehicle.segment === 'Upper Large') && vehicle.derivedBodyStyle === 'suvs';

  if (isLight) {
    sizeQuery = `${sizeQuery} AND segment:Small`;
  } else if (isLargeSuv) {
    sizeQuery = `${sizeQuery} AND segment:Medium`;
  } else if (isPeopleMover) {
    // Don't send segment for People Movers
    sizeQuery = `${sizeQuery}`;
  } else {
    sizeQuery = `${sizeQuery} AND segment:${vehicle.segment}`;
  }

  // Is not a micro/light/small car
  const isNotLight = `(NOT segment:Micro AND NOT segment:Light)`;

  // Finally string it al together
  const query = [
    `isRecommendable:true`,
    priceQuery,
    yearQuery,
    ancapSpecificQuery,
    sizeQuery,
    isNotLight,
    ratingQuery,
    safetyFeatureQuery,
  ].join(' AND ');

  return query;
};
