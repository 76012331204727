import _ from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import Container from '@mui/material/Container';

import { useGutterSpacing } from '../../hooks/use-gutter-spacing';
import { H3 } from '../type';

import {
  ContentGridWrapper,
  Image,
  ImageWrapper,
  ItemBody,
  ItemHeader,
  ItemWrapper,
  StyledGrid,
  Title,
} from './styles';

// @ts-expect-error [🤖 PLEASE FIX 🤖]: Binding element 'items' implicitly has an 'any' type.
const ContentGridBlock = ({ heading = null, items }) => {
  const itemCount = _.size(items);
  const columnsOnDesktop = itemCount !== 2 && itemCount <= 4 ? 12 / itemCount : 4; // Set to max of 4 items in admin
  const hasMoreThanOneItem = itemCount > 1;

  return (
    <Container maxWidth="xl">
      <ContentGridWrapper>
        {heading && (
          <Title>
            <H3>{heading}</H3>
          </Title>
        )}
        <StyledGrid container spacing={useGutterSpacing()}>
          {_.map(items, (item, index) => (
            <StyledGrid item xs={12} sm={hasMoreThanOneItem ? 6 : 12} lg={columnsOnDesktop} key={index}>
              <ItemWrapper>
                {!_.isEmpty(item.image) && (
                  <ImageWrapper>
                    {/* @ts-expect-error [🤖 PLEASE FIX 🤖]: Object is of type 'unknown'. */}
                    <Image src={_.head(item.image).url} alt={item.heading} />
                  </ImageWrapper>
                )}
                {item.heading && <ItemHeader>{item.heading}</ItemHeader>}
                {item.body && <ItemBody>{item.body}</ItemBody>}
              </ItemWrapper>
            </StyledGrid>
          ))}
        </StyledGrid>
      </ContentGridWrapper>
    </Container>
  );
};

ContentGridBlock.query = `
  ... on contentMatrix_contentGridBlock_BlockType {
    id
    typeHandle
    slug
    heading
    items {
      ... on contentGridItems_contentGridItems_Entry {
        heading
        body
        image {
          url
        }
      }
    }
  }
  `;

ContentGridBlock.propTypes = {
  heading: PropTypes.string,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      image: PropTypes.arrayOf(
        PropTypes.shape({
          url: PropTypes.string,
        })
      ),
      heading: PropTypes.string,
      body: PropTypes.string,
    })
  ).isRequired,
};

export default ContentGridBlock;
