import _ from 'lodash';

import { fetch } from '../../../api';
import logger from '../../../logger';

// @ts-expect-error [🤖 PLEASE FIX 🤖]: Parameter 'path' implicitly has an 'any' type.
const fetchSeoData = async (path) => {
  const seoQuery = `{
    seomatic (uri: "${path}", asArray: true) {
      metaTitleContainer
      metaTagContainer
      metaLinkContainer
      metaScriptContainer
      metaJsonLdContainer
    }
  }`;
  // @ts-expect-error [🤖 PLEASE FIX 🤖]: Expected 2 arguments, but got 1.
  const seoResponse = await fetch({ query: seoQuery });
  const seomatic = _.get(seoResponse, 'data.data.seomatic');
  if (!_.isPlainObject(seomatic)) {
    return null;
  }
  return _.mapValues(seomatic, (value, key) => {
    const parsed = _.attempt(JSON.parse, value);
    if (_.isError(parsed)) {
      logger.error('SEO parsing failed', { key, value, error: parsed }, { path });
      return null;
    }
    return parsed;
  });
};

export default fetchSeoData;
