import React from 'react';
import styled from '@emotion/styled';
import { isNumber } from 'lodash';
import Grid from '@mui/material/Grid';
import { spacing as muiSpacing } from '@mui/system';

import { GRID, BREAKPOINTS } from '../../constants';

// @ts-expect-error [🤖 PLEASE FIX 🤖]: Parameter 'offset' implicitly has an 'any' type.
const getGutterStyles = (offset) => `
  &.MuiGrid-item {
    margin-left: ${offset ? offset[0] : 0};

    @media (min-width: ${BREAKPOINTS.SM}px) {
      margin-left: ${offset[1]};
    }

    @media (min-width: ${BREAKPOINTS.MD}px) {
      margin-left: ${offset[2]};
    }

    @media (min-width: ${BREAKPOINTS.LG}px) {
      margin-left: ${offset[3]};
    }

    @media (min-width: ${BREAKPOINTS.XL}px) {
      margin-left: ${offset[4]};
    }
  }
`;

const StyledCol = styled(Grid)`
  ${
    /* @ts-expect-error [🤖 PLEASE FIX 🤖]: Property 'offset' does not exist on type 'GridOwnProps & CommonProps & Omit<Omit<DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>, "ref"> & { ...; }, "xl" | ... 120 more ... | "zeroMinWidth"> & { ...; } & { ...; }'. */
    ({ spacing, offset }) => !isNumber(spacing) && offset.length > 0 && getGutterStyles(offset)
  };

  padding-left: ${GRID.GUTTER_HALF};
  padding-right: ${GRID.GUTTER_HALF};

  ${muiSpacing}
`;

// @ts-expect-error [🤖 PLEASE FIX 🤖]: Parameter 'v' implicitly has an 'any' type.
const toPercent = (v) => `${v * 100}%`;

// @ts-expect-error [🤖 PLEASE FIX 🤖]: Binding element 'className' implicitly has an 'any' type.
const Col = ({ className, offset, width, ...props }) => (
  <StyledCol {...props} {...width} className={className} item offset={offset ? offset.map(toPercent) : []} />
);

export default Col;
