import MuiCheckbox from '@mui/material/Checkbox';
import MuiFormControl from '@mui/material/FormControl';
import MuiFormControlLabel from '@mui/material/FormControlLabel';
import MuiFormHelperText from '@mui/material/FormHelperText';
import PropTypes from 'prop-types';
import React from 'react';
import styled from '@emotion/styled';

import RichText from '../rich-text';
import { SPACE, BREAKPOINTS } from '../../constants';

import { fieldStyles, formTheme } from './field-styles';

const Wrapper = styled.div`
  .MuiSvgIcon-root {
    font-size: ${formTheme.typography.icon.fontSize.xs};

    @media (min-width: ${BREAKPOINTS.MD}px) {
      font-size: ${formTheme.typography.icon.fontSize.md};
    }

    ${(props) =>
      // @ts-expect-error [🤖 PLEASE FIX 🤖]: Property 'error' does not exist on type '{ theme?: Theme | undefined; as?: ElementType<any, keyof IntrinsicElements> | undefined; } & ClassAttributes<HTMLDivElement> & HTMLAttributes<...> & { ...; }'. Did you mean 'onError'?
      props.error &&
      `
          fill: ${formTheme.palette.error.main};
      `}
  }

  .Mui-checked {
    .MuiSvgIcon-root {
      fill: ${formTheme.palette.secondary.dark};

      ${(props) =>
        // @ts-expect-error [🤖 PLEASE FIX 🤖]: Property 'error' does not exist on type '{ theme?: Theme | undefined; as?: ElementType<any, keyof IntrinsicElements> | undefined; } & ClassAttributes<HTMLDivElement> & HTMLAttributes<...> & { ...; }'. Did you mean 'onError'?
        props.error &&
        `
            fill: ${formTheme.palette.error.main};
        `}
    }
  }

  .MuiButtonBase-root {
    .MuiTouchRipple-root {
      color: ${
        /* @ts-expect-error [🤖 PLEASE FIX 🤖]: Property 'light' does not exist on type '{ main: string; dark: string; }'. */
        formTheme.palette.secondary.light
      };
    }
  }
`;

const FormControlLabelWrapper = styled.div`
  .MuiCheckbox-root {
    align-self: flex-start;
    padding: ${SPACE.X0};
  }

  .MuiFormControlLabel-label {
    padding: calc(${SPACE.X1} / 2) 0;
    font-size: ${formTheme.typography.label.fontSize.xs};
    color: ${formTheme.palette.secondary.main};

    @media (min-width: ${BREAKPOINTS.MD}px) {
      font-size: ${formTheme.typography.label.fontSize.md};
      padding: 0;
    }

    p {
      font-size: ${formTheme.typography.label.p.fontSize.xs};
      margin: 0;

      @media (min-width: ${BREAKPOINTS.MD}px) {
        padding: calc(${SPACE.X1} / 4) 0;
        font-size: ${formTheme.typography.label.p.fontSize.md};
      }
    }
  }
`;

const HelperTextWrapper = styled.div`
  .MuiFormHelperText-root {
    ${fieldStyles.helperText}
    margin: 0 12px 0;
  }
`;

const Checkbox = ({ label = null, error = false, helperText = undefined, ...props }) => {
  const labelNode = <RichText>{label}</RichText>;

  return (
    <Wrapper>
      <MuiFormControl error={error}>
        <FormControlLabelWrapper>
          <MuiFormControlLabel control={<MuiCheckbox {...props} />} label={labelNode} labelPlacement="end" />
        </FormControlLabelWrapper>
        {helperText && (
          <HelperTextWrapper>
            <MuiFormHelperText>{helperText}</MuiFormHelperText>
          </HelperTextWrapper>
        )}
      </MuiFormControl>
    </Wrapper>
  );
};

Checkbox.propTypes = {
  error: PropTypes.bool,
  helperText: PropTypes.string,
  label: PropTypes.string,
};

export default Checkbox;
