import React from 'react';
import styled from '@emotion/styled';
import MuiBox from '@mui/material/Box';
import { spacing } from '@mui/system';

const StyledBox = styled(MuiBox)`
  ${spacing}
`;

// @ts-expect-error [🤖 PLEASE FIX 🤖]: Binding element 'children' implicitly has an 'any' type.
const Box = ({ children, className, ...props }) => (
  <StyledBox {...props} className={className}>
    {children}
  </StyledBox>
);

export default Box;
