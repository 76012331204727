import _ from 'lodash';
import { CARDS_TO_DISPLAY } from '@/components/similar-but-safer/constants';

// @ts-expect-error [🤖 PLEASE FIX 🤖]: Parameter 'vehicles' implicitly has an 'any' type.
export const cutOutMatchingMakeModels = (vehicles, makeDescription, modelDescription, year) => {
  const vehiclesWithSameMakeModel = _.filter(
    // @ts-expect-error [🤖 PLEASE FIX 🤖]: 'vehicle' is of type 'unknown'.
    _.remove(vehicles, (vehicle) => vehicle.make === makeDescription && vehicle.model === modelDescription),
    // @ts-expect-error [🤖 PLEASE FIX 🤖]: 'vehicle' is of type 'unknown'.
    (vehicle) => vehicle.year > year
  );

  // @ts-expect-error [🤖 PLEASE FIX 🤖]: 'vehicle' is of type 'unknown'.
  const latestVehicleWithSameMakeModel = _.maxBy(vehiclesWithSameMakeModel, (vehicle) => vehicle.year);

  if (latestVehicleWithSameMakeModel) {
    return _.concat(latestVehicleWithSameMakeModel, _.take(vehicles, CARDS_TO_DISPLAY - 1));
  }
  return _.take(vehicles, CARDS_TO_DISPLAY);
};
