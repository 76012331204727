import { connectHits } from 'react-instantsearch-dom';
import { Row, Box } from '@/components/grid';
import MinicartListing from '@/components/similar-but-safer/components/minicart-listing';
import { filterRawAlgoliaHits } from '@/components/similar-but-safer/utils/filter-raw-algolia-hits';
import { QuickCompareHeader } from '@/components/similar-but-safer/components/connected-minicart-listings/styles';

// @ts-expect-error [🤖 PLEASE FIX 🤖]: Binding element 'makeDescription' implicitly has an 'any' type.
export const MinicartListings = ({ makeDescription, modelDescription, year, hits }) => {
  const filteredHits = filterRawAlgoliaHits({ makeDescription, modelDescription, year, hits });
  if (filteredHits.length === 0) {
    return null;
  }

  return (
    // @ts-expect-error [🤖 PLEASE FIX 🤖]: Property 'className' is missing in type '{ children: Element[]; }' but required in type '{ [x: string]: any; children: any; className: any; }'.
    <Box>
      <QuickCompareHeader as="h2">Quick compare</QuickCompareHeader>
      {/* @ts-expect-error [🤖 PLEASE FIX 🤖]: Type '{ children: Element[]; }' is missing the following properties from type '{ [x: string]: any; className: any; children: any; spacing: any; }': className, spacing */}
      <Row>
        {filteredHits.slice(0, 3).map((hit) => (
          // @ts-expect-error [🤖 PLEASE FIX 🤖]: Property 'addToCompare' is missing in type '{ key: any; hit: any; }' but required in type 'Omit<Pick<{ hit: any; addToCompare: any; }, never> & Pick<InferProps<{ addToCompare: Validator<(...args: any[]) => any>; }>, "addToCompare"> & Pick<{ hit: any; addToCompare: any; }, "hit">, never>'.
          <MinicartListing key={hit.objectID} hit={hit} />
        ))}
      </Row>
    </Box>
  );
};

const ConnectedMinicartListings = connectHits(MinicartListings);

export default ConnectedMinicartListings;
