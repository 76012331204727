import { map, inRange, uniqBy } from 'lodash';
import { Hidden } from '@mui/material';
import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import React from 'react';

import { ALERT_STATUSES } from '@/constants/statuses';
import { vehicleImagePropTypes } from '@/utils/get-vehicle-image/index';
import { H5 } from '@/components/type';
import { URLS } from '@/constants';
import { PrimaryButton } from '@/components/button';
import Alert from '@/components/alert';
import Icon, { iconNames } from '@/components/icons';
import MiniCartItem from '@/components/mini-cart-item';
import { Panel } from '@/components/panel';
import ConnectedMinicartListings from '@/components/similar-but-safer/components/connected-minicart-listings';
import SimilarButSafer from '@/components/similar-but-safer';

// @ts-expect-error [🤖 PLEASE FIX 🤖]: An import path can only end with a '.ts' extension when 'allowImportingTsExtensions' is enabled.
import { CART_MAXIMUM, DISCOVERY_LINKS } from './constants.ts';
import {
  AlertWrapper,
  StyledBody,
  StyledFooter,
  StyledH4,
  StyledHeader,
  StyledNavLink,
  StyledNavLinkText,
  StyledSmallTitle,
  StyledVehicleLinks,
} from './styles';

// @ts-expect-error [🤖 PLEASE FIX 🤖]: Binding element 'links' implicitly has an 'any' type.
const LinksList = ({ links, onClose }) => {
  return map(links, (link, index) => (
    <StyledVehicleLinks key={`vehiclelink-${index}`}>
      {/* @ts-expect-error [🤖 PLEASE FIX 🤖]: Type 'string' is not assignable to type 'number'. */}
      <StyledNavLink tabIndex="0" onClick={onClose} href={link.href}>
        <H5 as="h2">
          <StyledNavLinkText>{link.linkText}</StyledNavLinkText>
          <StyledNavLinkText>
            <strong>{link.price}</strong>
          </StyledNavLinkText>
        </H5>
        <Icon name={iconNames.linkActive} />
      </StyledNavLink>
    </StyledVehicleLinks>
  ));
};

const Minicart = ({
  // @ts-expect-error [🤖 PLEASE FIX 🤖]: Binding element 'filterActions' implicitly has an 'any' type.
  filterActions,
  isOpen = false,
  // @ts-expect-error [🤖 PLEASE FIX 🤖]: Binding element 'onClose' implicitly has an 'any' type.
  onClose,
  alertMessage = null,
  // @ts-expect-error [🤖 PLEASE FIX 🤖]: Binding element 'removeFromCompare' implicitly has an 'any' type.
  removeFromCompare,
  vehicleIds = [],
  vehicles = [],
  compareVehicleCount = 0,
}) => {
  const router = useRouter();
  const canCompareVehicles = inRange(compareVehicleCount, 2, CART_MAXIMUM + 1);

  return (
    // @ts-expect-error [🤖 PLEASE FIX 🤖]: This JSX tag's 'children' prop expects a single child of type 'null | undefined', but multiple children were provided.
    <Panel anchor="right" ariaLabelledBy="compare-panel" isOpen={isOpen} onClose={onClose}>
      <StyledBody>
        <StyledHeader>
          {/* @ts-expect-error [🤖 PLEASE FIX 🤖]: Type 'string' is not assignable to type 'number'. */}
          <H5 id="compare-panel" tabIndex="-1" as="h1">
            Compare: {compareVehicleCount}
          </H5>
        </StyledHeader>
        {map(uniqBy(vehicles, 'redbookCode'), (vehicle) => (
          // @ts-expect-error [🤖 PLEASE FIX 🤖]: Property 'redbookCode' does not exist on type 'never'.
          <MiniCartItem key={vehicle?.redbookCode} {...{ vehicle, removeFromCompare }} />
        ))}
        {compareVehicleCount === 0 && (
          <>
            <StyledH4 as="h2">No cars selected</StyledH4>
            <StyledSmallTitle>Browse safe cars for:</StyledSmallTitle>
            <LinksList {...{ filterActions, links: DISCOVERY_LINKS.NO_VEHICLE, onClose, router }} />
          </>
        )}
        {compareVehicleCount === 1 && (
          <>
            {/* @ts-expect-error [🤖 PLEASE FIX 🤖]: Type 'ConnectedComponentClass<{ makeDescription: any; modelDescription: any; year: any; hits: any; }, HitsProvided<unknown>, {}>' is not assignable to type '() => null'. */}
            <SimilarButSafer vehicle={vehicles[0]} ConnectedComponent={ConnectedMinicartListings} />
            <LinksList {...{ filterActions, links: DISCOVERY_LINKS.SINGLE_VEHICLE, onClose, router }} />
          </>
        )}
        {compareVehicleCount > 1 && (
          <LinksList {...{ filterActions, links: DISCOVERY_LINKS.MULTIPLE_VEHICLES, onClose, router }} />
        )}
      </StyledBody>
      {compareVehicleCount > 0 && (
        <StyledFooter>
          {alertMessage && (
            <AlertWrapper>
              <Alert status={ALERT_STATUSES.error} content={alertMessage} />
            </AlertWrapper>
          )}
          <PrimaryButton
            disabled={!canCompareVehicles}
            href={`${URLS.COMPARE}?vehicles=${vehicleIds.join(',')}`}
            fullWidth
            disableRipple
          >
            Compare <Hidden mdDown>Cars</Hidden>
          </PrimaryButton>
        </StyledFooter>
      )}
    </Panel>
  );
};

Minicart.propTypes = {
  alertMessage: PropTypes.string,
  compareVehicleCount: PropTypes.number,
  filterActions: PropTypes.shape({
    addLifestyle: PropTypes.func.isRequired,
    reset: PropTypes.func.isRequired,
    updateSelectedPriceRange: PropTypes.func.isRequired,
  }).isRequired,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  removeFromCompare: PropTypes.func.isRequired,
  vehicleIds: PropTypes.arrayOf(PropTypes.string),
  vehicles: PropTypes.arrayOf(
    PropTypes.shape({
      ...vehicleImagePropTypes,
      name: PropTypes.string,
      ancapRating: PropTypes.number,
      priceRangeLow: PropTypes.number,
      priceRangeHigh: PropTypes.number,
      newPrice: PropTypes.number,
    })
  ),
};

export default Minicart;
