// @ts-expect-error [🤖 PLEASE FIX 🤖]: Could not find a declaration file for module 'react-scroll'. '/Users/aaronpierce/workspace/tac-hsiyc-web/node_modules/react-scroll/modules/index.js' implicitly has an 'any' type.
import { scroller } from 'react-scroll';
import { withFormik } from 'formik';
import _ from 'lodash';
import * as Yup from 'yup';
import React, { useEffect, useRef } from 'react';
import styled from '@emotion/styled';

import { PrimaryButton } from '../button';
import { Row, Col } from '../grid';
import { TextField, Recaptcha } from '../form-fields';
import { ControlledSelect } from '../form-fields/select';
import formValidation from '../../utils/form-validation';
import Alert from '../alert';
import { ALERT_STATUSES } from '../../constants/statuses';

const getSiteKey = () => process.env.RECAPTCHA_SITE_KEY || '6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI'; // do not remove - required for recaptcha in storybook

const StyledCol = styled(Col)`
  padding-bottom: 1rem;
  position: relative;
`;

const ErrorCol = styled(Col)`
  position: relative;
`;

const states = ['ACT', 'NSW', 'NT', 'QLD', 'SA', 'TAS', 'VIC', 'WA'];
const options = _.map(states, (state) => ({ value: state, label: state }));

const colWidth = { xs: 12, lg: 10, xl: 8 };
const colOffset = [0, 0, 0, 1 / 12, 2 / 12];
const semiColWidth = { xs: 12, md: 6, lg: 5, xl: 4 };

// @ts-expect-error [🤖 PLEASE FIX 🤖]: Parameter 'ref' implicitly has an 'any' type.
const setFocus = (ref) =>
  setTimeout(() => {
    ref.current.focus();
  }, 100);

const Form = ({
  // @ts-expect-error [🤖 PLEASE FIX 🤖]: Binding element 'errors' implicitly has an 'any' type.
  errors,
  // @ts-expect-error [🤖 PLEASE FIX 🤖]: Binding element 'handleChange' implicitly has an 'any' type.
  handleChange,
  // @ts-expect-error [🤖 PLEASE FIX 🤖]: Binding element 'handleReset' implicitly has an 'any' type.
  handleReset,
  // @ts-expect-error [🤖 PLEASE FIX 🤖]: Binding element 'handleSubmit' implicitly has an 'any' type.
  handleSubmit,
  // @ts-expect-error [🤖 PLEASE FIX 🤖]: Binding element 'isSubmitting' implicitly has an 'any' type.
  isSubmitting,
  // @ts-expect-error [🤖 PLEASE FIX 🤖]: Binding element 'setStatus' implicitly has an 'any' type.
  setStatus,
  // @ts-expect-error [🤖 PLEASE FIX 🤖]: Binding element 'status' implicitly has an 'any' type.
  status,
  // @ts-expect-error [🤖 PLEASE FIX 🤖]: Binding element 'successMessage' implicitly has an 'any' type.
  successMessage,
  // @ts-expect-error [🤖 PLEASE FIX 🤖]: Binding element 'touched' implicitly has an 'any' type.
  touched,
  // @ts-expect-error [🤖 PLEASE FIX 🤖]: Binding element 'values' implicitly has an 'any' type.
  values,
}) => {
  const fNameRef = useRef();
  const lNameRef = useRef();
  const emailRef = useRef();
  const messageRef = useRef();

  // @ts-expect-error [🤖 PLEASE FIX 🤖]: Parameter 'id' implicitly has an 'any' type.
  const scrollTo = (id) => {
    if (document.getElementById(id)) {
      scroller.scrollTo(id, {
        duration: 800,
        offset: -50,
        smooth: true,
      });
    }
  };

  React.useEffect(() => {
    const validationErrors = Object.keys(errors);

    if (isSubmitting) {
      if (status === 'success') {
        scrollTo('form-success');
      } else if (validationErrors.length > 0) {
        if (!errors.general) {
          scrollTo(_.omit(validationErrors, 'general')[0]);
        }
      }
    }
  }, [status]);

  // @ts-expect-error [🤖 PLEASE FIX 🤖]: Parameter 'props' implicitly has an 'any' type.
  const resetForm = (props) => {
    setStatus(null);
    scrollTo('form-success');
    handleReset({ ...props });
  };

  const isSuccess = status === 'success';

  useEffect(() => {
    if (fNameRef && errors.firstName) {
      setFocus(fNameRef);
    } else if (lNameRef && errors.lastName) {
      setFocus(lNameRef);
    } else if (emailRef && errors.fromEmail) {
      setFocus(emailRef);
    } else if (messageRef && errors.message) {
      setFocus(messageRef);
    }
  }, [fNameRef, lNameRef, emailRef, messageRef, isSubmitting]);

  return (
    <>
      {isSuccess && (
        // @ts-expect-error [🤖 PLEASE FIX 🤖]: Type '{ children: Element[]; wrap: string; id: string; }' is missing the following properties from type '{ [x: string]: any; className: any; children: any; spacing: any; }': className, spacing
        <Row wrap="wrap" id="form-success">
          {/* @ts-expect-error [🤖 PLEASE FIX 🤖]: Property 'className' is missing in type '{ children: Element; width: { xs: number; lg: number; xl: number; }; offset: number[]; }' but required in type '{ [x: string]: any; className: any; offset: any; width: any; }'. */}
          <StyledCol width={colWidth} offset={colOffset}>
            <Alert id="success" status={ALERT_STATUSES.success} content={successMessage} />
          </StyledCol>
          {/* @ts-expect-error [🤖 PLEASE FIX 🤖]: Property 'className' is missing in type '{ children: Element; width: { xs: number; md: number; lg: number; xl: number; }; offset: number[]; }' but required in type '{ [x: string]: any; className: any; offset: any; width: any; }'. */}
          <StyledCol width={semiColWidth} offset={colOffset}>
            <PrimaryButton type="button" disabled={isSubmitting} onClick={resetForm}>
              Reset
            </PrimaryButton>
          </StyledCol>
        </Row>
      )}
      {!isSuccess && (
        <form onSubmit={handleSubmit} autoComplete="on" aria-label="contact form">
          {/* @ts-expect-error [🤖 PLEASE FIX 🤖]: Type '{ children: any[]; wrap: string; }' is missing the following properties from type '{ [x: string]: any; className: any; children: any; spacing: any; }': className, spacing */}
          <Row wrap="wrap">
            {/* @ts-expect-error [🤖 PLEASE FIX 🤖]: Property 'className' is missing in type '{ children: Element; width: { xs: number; md: number; lg: number; xl: number; }; offset: number[]; }' but required in type '{ [x: string]: any; className: any; offset: any; width: any; }'. */}
            <StyledCol width={semiColWidth} offset={colOffset}>
              <TextField
                // @ts-expect-error [🤖 PLEASE FIX 🤖]: Type '{ error: any; fullWidth: true; helperText: any; id: string; label: string; onChange: any; value: any; disabled: any; autoComplete: string; inputRef: MutableRefObject<undefined>; }' is not assignable to type 'IntrinsicAttributes & RefAttributes<unknown>'.
                error={touched.firstName && Boolean(errors.firstName)}
                fullWidth
                helperText={touched.firstName ? errors.firstName : ''}
                id="firstName"
                label="First Name"
                onChange={handleChange}
                value={values.firstName}
                disabled={isSubmitting}
                autoComplete="given-name"
                inputRef={fNameRef}
              />
            </StyledCol>
            {/* @ts-expect-error [🤖 PLEASE FIX 🤖]: Type '{ children: Element; width: { xs: number; md: number; lg: number; xl: number; }; }' is missing the following properties from type '{ [x: string]: any; className: any; offset: any; width: any; }': className, offset */}
            <StyledCol width={semiColWidth}>
              <TextField
                // @ts-expect-error [🤖 PLEASE FIX 🤖]: Type '{ error: any; fullWidth: true; helperText: any; id: string; label: string; onChange: any; value: any; disabled: any; autoComplete: string; inputRef: MutableRefObject<undefined>; }' is not assignable to type 'IntrinsicAttributes & RefAttributes<unknown>'.
                error={touched.lastName && Boolean(errors.lastName)}
                fullWidth
                helperText={touched.lastName ? errors.lastName : ''}
                id="lastName"
                label="Last Name"
                onChange={handleChange}
                value={values.lastName}
                disabled={isSubmitting}
                autoComplete="family-name"
                inputRef={lNameRef}
              />
            </StyledCol>
            {/* @ts-expect-error [🤖 PLEASE FIX 🤖]: Property 'className' is missing in type '{ children: Element; width: { xs: number; md: number; lg: number; xl: number; }; offset: number[]; }' but required in type '{ [x: string]: any; className: any; offset: any; width: any; }'. */}
            <StyledCol width={semiColWidth} offset={colOffset}>
              <TextField
                // @ts-expect-error [🤖 PLEASE FIX 🤖]: Type '{ error: any; fullWidth: true; helperText: any; id: string; label: string; onChange: any; type: string; value: any; disabled: any; autoComplete: string; inputRef: MutableRefObject<undefined>; }' is not assignable to type 'IntrinsicAttributes & RefAttributes<unknown>'.
                error={touched.fromEmail && Boolean(errors.fromEmail)}
                fullWidth
                helperText={touched.fromEmail ? errors.fromEmail : ''}
                id="fromEmail"
                label="Email"
                onChange={handleChange}
                type="email"
                value={values.fromEmail}
                disabled={isSubmitting}
                autoComplete="email"
                inputRef={emailRef}
              />
            </StyledCol>
            {/* @ts-expect-error [🤖 PLEASE FIX 🤖]: Type '{ children: Element; width: { xs: number; md: number; lg: number; xl: number; }; }' is missing the following properties from type '{ [x: string]: any; className: any; offset: any; width: any; }': className, offset */}
            <StyledCol width={semiColWidth}>
              <ControlledSelect
                error={touched.state && Boolean(errors.state)}
                helperText={touched.state ? errors.state : ''}
                // @ts-expect-error [🤖 PLEASE FIX 🤖]: Type 'string' is not assignable to type 'null | undefined'.
                id="state"
                // @ts-expect-error [🤖 PLEASE FIX 🤖]: Type 'string' is not assignable to type 'null | undefined'.
                label="State"
                onChange={handleChange('state')}
                // @ts-expect-error [🤖 PLEASE FIX 🤖]: Type '{ value: string; label: string; }[]' is not assignable to type 'null | undefined'.
                options={options}
                value={values.state}
              />
            </StyledCol>
            {/* @ts-expect-error [🤖 PLEASE FIX 🤖]: Property 'className' is missing in type '{ children: Element; width: { xs: number; lg: number; xl: number; }; offset: number[]; }' but required in type '{ [x: string]: any; className: any; offset: any; width: any; }'. */}
            <StyledCol width={colWidth} offset={colOffset}>
              <TextField
                // @ts-expect-error [🤖 PLEASE FIX 🤖]: Type '{ error: any; fullWidth: true; helperText: any; id: string; label: string; multiline: true; onChange: any; rows: string; value: any; disabled: any; inputRef: MutableRefObject<undefined>; }' is not assignable to type 'IntrinsicAttributes & RefAttributes<unknown>'.
                error={touched.message && Boolean(errors.message)}
                fullWidth
                helperText={touched.message ? errors.message : ''}
                id="message"
                label="Message"
                multiline
                onChange={handleChange}
                rows="4"
                value={values.message}
                disabled={isSubmitting}
                inputRef={messageRef}
              />
            </StyledCol>
            {/* @ts-expect-error [🤖 PLEASE FIX 🤖]: Property 'className' is missing in type '{ children: Element; width: { xs: number; md: number; lg: number; xl: number; }; offset: number[]; }' but required in type '{ [x: string]: any; className: any; offset: any; width: any; }'. */}
            <StyledCol width={semiColWidth} offset={colOffset}>
              <Recaptcha
                error={touched.recaptcha && Boolean(errors.recaptcha)}
                helperText={touched.recaptcha ? errors.recaptcha : ''}
                id="recaptcha"
                sitekey={getSiteKey()}
                onChange={handleChange('recaptcha')}
              />
            </StyledCol>
            {/* @ts-expect-error [🤖 PLEASE FIX 🤖]: Type '{ children: Element; width: { xs: number; md: number; lg: number; xl: number; }; }' is missing the following properties from type '{ [x: string]: any; className: any; offset: any; width: any; }': className, offset */}
            <StyledCol width={semiColWidth}>
              <PrimaryButton type="submit" disabled={isSubmitting} isLoading={isSubmitting} fullWidth>
                {isSubmitting ? 'Submitting…' : 'Submit'}
              </PrimaryButton>
            </StyledCol>
            {errors.general && (
              // @ts-expect-error [🤖 PLEASE FIX 🤖]: Property 'className' is missing in type '{ children: Element; id: string; width: { xs: number; lg: number; xl: number; }; offset: number[]; }' but required in type '{ [x: string]: any; className: any; offset: any; width: any; }'.
              <ErrorCol id="general" width={colWidth} offset={colOffset}>
                <Alert id="error" status={ALERT_STATUSES.error} content={errors.general} />
              </ErrorCol>
            )}
          </Row>
        </form>
      )}
    </>
  );
};

export default withFormik({
  // @ts-expect-error [🤖 PLEASE FIX 🤖]: Property 'firstName' does not exist on type '{}'.
  mapPropsToValues: ({ firstName, lastName, fromEmail, state, message, recaptcha, subject }) => {
    return {
      action: 'contact-form/send',
      firstName: firstName || '',
      lastName: lastName || '',
      fromEmail: fromEmail || '',
      state: state || '',
      message: message || '',
      recaptcha: recaptcha || null,
      ...(subject && { subject }),
    };
  },

  validate: ({ recaptcha }) => {
    const errors = {};
    if (!recaptcha) {
      // @ts-expect-error [🤖 PLEASE FIX 🤖]: Property 'recaptcha' does not exist on type '{}'.
      errors.recaptcha = 'reCAPTCHA is required';
    }
    return errors;
  },

  validationSchema: Yup.object().shape({
    firstName: formValidation.requiredFirstName,
    lastName: formValidation.requiredLastName,
    fromEmail: formValidation.requiredEmail,
    message: formValidation.requiredMessage,
  }),

  handleSubmit: async (values, bag) => {
    const { props } = bag;
    const { message, state, firstName, lastName, recaptcha, ...rest } = values;
    const formData = {
      'message[State]': state,
      'message[Body]': message,
      fromName: `${firstName} ${lastName}`,
      'g-recaptcha-response': recaptcha,
      ...rest,
    };

    // @ts-expect-error [🤖 PLEASE FIX 🤖]: Property 'handleSubmit' does not exist on type 'object'.
    await props.handleSubmit(formData, bag);
  },
})(Form);
