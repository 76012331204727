import Drawer from '@mui/material/Drawer';
import Paper from '@mui/material/Paper';
import PropTypes from 'prop-types';
import React from 'react';
import Box from '@mui/material/Box';
import { IconButton } from '../icon-button';
import { iconNames } from '../icons';
import { SPACE } from '../../constants';
import styled from '@emotion/styled';

const CloseButtonBox = styled(Box)`
  background: transparent;
  display: block;
  position: absolute;

  left: ${
    /* @ts-expect-error [🤖 PLEASE FIX 🤖]: Property 'anchor' does not exist on type 'BoxOwnProps<Theme> & Omit<Omit<DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>, "ref"> & { ...; }, keyof BoxOwnProps<...>> & { ...; } & { ...; }'. */
    (props) => (props.anchor === 'left' ? SPACE.X1 : 'auto')
  };

  right: ${
    /* @ts-expect-error [🤖 PLEASE FIX 🤖]: Property 'anchor' does not exist on type 'BoxOwnProps<Theme> & Omit<Omit<DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>, "ref"> & { ...; }, keyof BoxOwnProps<...>> & { ...; } & { ...; }'. */
    (props) => (props.anchor === 'right' ? SPACE.X1 : 'auto')
  };
  top: ${SPACE.X1};
  z-index: 1;
`;

const StyledPaper = styled(Paper)`
  width: 584px;
  max-width: 90%;
  min-height: 100%;
`;

const StyledIconButton = styled(IconButton)`
  padding: 12px;
`;

const Panel = ({
  anchor = 'left',
  ariaLabel = null,
  ariaLabelledBy = null,
  children = null,
  isOpen = false,
  onClose = () => {},
}) => {
  return (
    <Drawer
      // @ts-expect-error [🤖 PLEASE FIX 🤖]: Type 'string' is not assignable to type '"bottom" | "left" | "right" | "top" | undefined'.
      anchor={anchor}
      open={isOpen}
      onClose={onClose}
      role="dialog"
      aria-label={ariaLabel}
      aria-labelledby={ariaLabelledBy}
      variant="temporary"
      PaperProps={{
        component: StyledPaper,
      }}
    >
      {/* @ts-expect-error [🤖 PLEASE FIX 🤖]: Type '{ children: Element; anchor: string; }' is not assignable to type 'IntrinsicAttributes & BoxOwnProps<Theme> & Omit<Omit<DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>, "ref"> & { ...; }, keyof BoxOwnProps<...>> & { ...; }'. */}
      <CloseButtonBox anchor={anchor}>
        {/* @ts-expect-error [🤖 PLEASE FIX 🤖]: Type '{ ariaLabel: string; iconName: "filter" | "visibility" | "caret" | "download" | "close" | "compareCar" | "cross" | "expand" | "didYouKnow" | "facebook" | "hamburger" | "instagram" | ... 13 more ... | "warning"; name: string; onClick: () => void; }' is missing the following properties from type '{ ariaLabel: any; className: any; edge: any; iconName: any; onClick: any; name: any; }': className, edge */}
        <StyledIconButton
          ariaLabel="Close navigation"
          iconName={iconNames.close}
          name="Close navigation"
          onClick={onClose}
        />
      </CloseButtonBox>
      {children}
    </Drawer>
  );
};

Panel.propTypes = {
  ariaLabel: PropTypes.string,
  ariaLabelledBy: PropTypes.string,
  anchor: PropTypes.oneOf(['left', 'right']),
  isOpen: PropTypes.bool,
  children: PropTypes.node,
  onClose: PropTypes.func,
};

export { Panel };
