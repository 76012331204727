import { ApolloClient, ApolloError, HttpLink, InMemoryCache } from '@apollo/client';

export const createApolloClient = (uri = process.env.APPSYNC_API_URL, key = process.env.APPSYNC_API_KEY) => {
  const httpLink = new HttpLink({
    uri,
    headers: {
      // @ts-expect-error [🤖 PLEASE FIX 🤖]: Type 'string | undefined' is not assignable to type 'string'.
      'x-api-key': key,
    },
  });

  const cache = new InMemoryCache();

  const client = new ApolloClient({
    link: httpLink,
    cache,
  });

  return client;
};

// @ts-expect-error [🤖 PLEASE FIX 🤖]: Parameter 'error' implicitly has an 'any' type.
export const apolloAPIErrorHandler = (error) => {
  let errorMessage = '';

  if (error instanceof Error) {
    errorMessage = error.message;
  }

  if (error instanceof ApolloError) {
    const { networkError, graphQLErrors } = error;
    errorMessage = 'Error fetching data from server. Please try again.';

    if (graphQLErrors) {
      const errorsInString = graphQLErrors.map((err) => err.message);
      if (errorsInString.length > 0) {
        console.error('GraphQL errors', graphQLErrors);
        errorMessage = 'GraphQL errors: ' + graphQLErrors.map((err) => err.message).join(', ');
      }
    }

    if (networkError && 'statusCode' in networkError) {
      const { statusCode } = networkError;
      errorMessage = `Network error: ${statusCode}`;
    }
  }

  return errorMessage;
};
