import _ from 'lodash';

import initialState from './state';

// @ts-expect-error [🤖 PLEASE FIX 🤖]: Parameter 'state' implicitly has an 'any' type.
const addBodyStyle = (state, bodyStyle) => {
  return {
    ...state,
    selectedBodyStyles: _.concat(state.selectedBodyStyles, bodyStyle),
  };
};

// @ts-expect-error [🤖 PLEASE FIX 🤖]: Parameter 'state' implicitly has an 'any' type.
const addLifestyle = (state, lifestyle) => {
  return {
    ...state,
    selectedLifestyles: _.concat(state.selectedLifestyles, lifestyle),
  };
};

// @ts-expect-error [🤖 PLEASE FIX 🤖]: Parameter 'state' implicitly has an 'any' type.
const addMake = (state, make) => {
  return {
    ...state,
    selectedMakes: _.concat(state.selectedMakes, make),
  };
};

// @ts-expect-error [🤖 PLEASE FIX 🤖]: Parameter 'state' implicitly has an 'any' type.
const addSafetyFeature = (state, safetyFeature) => {
  return {
    ...state,
    selectedSafetyFeatures: _.concat(state.selectedSafetyFeatures, safetyFeature),
  };
};

// @ts-expect-error [🤖 PLEASE FIX 🤖]: Parameter 'state' implicitly has an 'any' type.
const addStarRating = (state, starRating) => {
  return {
    ...state,
    selectedStarRatings: _.concat(state.selectedStarRatings, starRating),
  };
};

// @ts-expect-error [🤖 PLEASE FIX 🤖]: Parameter 'state' implicitly has an 'any' type.
const addPowerType = (state, powerType) => {
  return {
    ...state,
    selectedPowerTypes: _.concat(state.selectedPowerTypes, powerType),
  };
};

// @ts-expect-error [🤖 PLEASE FIX 🤖]: Parameter 'state' implicitly has an 'any' type.
const removeLifestyle = (state, lifestyle) => {
  return {
    ...state,
    selectedLifestyles: _.filter(state.selectedLifestyles, (item) => item !== lifestyle),
  };
};

// @ts-expect-error [🤖 PLEASE FIX 🤖]: Parameter 'state' implicitly has an 'any' type.
const removeSafetyFeature = (state, safetyFeature) => {
  return {
    ...state,
    selectedSafetyFeatures: _.filter(state.selectedSafetyFeatures, (item) => item !== safetyFeature),
  };
};

// @ts-expect-error [🤖 PLEASE FIX 🤖]: Parameter 'state' implicitly has an 'any' type.
const removeStarRating = (state, starRating) => {
  return {
    ...state,
    selectedStarRatings: _.filter(state.selectedStarRatings, (item) => item !== starRating),
  };
};

// @ts-expect-error [🤖 PLEASE FIX 🤖]: Parameter 'state' implicitly has an 'any' type.
const removeBodyStyle = (state, bodyStyle) => {
  return {
    ...state,
    selectedBodyStyles: _.filter(state.selectedBodyStyles, (item) => item !== bodyStyle),
  };
};

// @ts-expect-error [🤖 PLEASE FIX 🤖]: Parameter 'state' implicitly has an 'any' type.
const removeMake = (state, make) => {
  return {
    ...state,
    selectedMakes: _.filter(state.selectedMakes, (item) => item !== make),
  };
};

// @ts-expect-error [🤖 PLEASE FIX 🤖]: Parameter 'state' implicitly has an 'any' type.
const removePowerType = (state, powerType) => {
  return {
    ...state,
    selectedPowerTypes: _.filter(state.selectedPowerTypes, (item) => item !== powerType),
  };
};

// @ts-expect-error [🤖 PLEASE FIX 🤖]: Parameter 'state' implicitly has an 'any' type.
const updateLifestyles = (state, selectedLifestyles) => {
  return {
    ...state,
    selectedLifestyles,
  };
};

// @ts-expect-error [🤖 PLEASE FIX 🤖]: Parameter 'state' implicitly has an 'any' type.
const updateMakes = (state, selectedMakes) => {
  return {
    ...state,
    selectedMakes,
  };
};

// @ts-expect-error [🤖 PLEASE FIX 🤖]: Parameter 'state' implicitly has an 'any' type.
const updateSafetyFeatures = (state, selectedSafetyFeatures) => {
  return {
    ...state,
    selectedSafetyFeatures,
  };
};

// @ts-expect-error [🤖 PLEASE FIX 🤖]: Parameter 'state' implicitly has an 'any' type.
const updateSelectedBodyStyles = (state, selectedBodyStyles) => {
  return {
    ...state,
    selectedBodyStyles,
  };
};

// @ts-expect-error [🤖 PLEASE FIX 🤖]: Parameter 'state' implicitly has an 'any' type.
const updateSelectedPriceRange = (state, selectedPriceRange) => {
  return {
    ...state,
    selectedPriceRange,
  };
};

// @ts-expect-error [🤖 PLEASE FIX 🤖]: Parameter 'state' implicitly has an 'any' type.
const updateUiSelectedPriceRange = (state, selectedPriceRange) => {
  return {
    ...state,
    uiSelectedPriceRange: selectedPriceRange,
  };
};

// @ts-expect-error [🤖 PLEASE FIX 🤖]: Parameter 'state' implicitly has an 'any' type.
const updateSelectedYearRange = (state, selectedYearRange) => {
  return {
    ...state,
    selectedYearRange,
  };
};

// @ts-expect-error [🤖 PLEASE FIX 🤖]: Parameter 'state' implicitly has an 'any' type.
const updateUiSelectedYearRange = (state, selectedYearRange) => {
  return {
    ...state,
    uiSelectedYearRange: selectedYearRange,
  };
};

// @ts-expect-error [🤖 PLEASE FIX 🤖]: Parameter 'state' implicitly has an 'any' type.
const updateStarRatings = (state, selectedStarRatings) => {
  return {
    ...state,
    selectedStarRatings,
  };
};

// @ts-expect-error [🤖 PLEASE FIX 🤖]: Parameter 'state' implicitly has an 'any' type.
const updatePowerTypes = (state, selectedPowerTypes) => {
  return {
    ...state,
    selectedPowerTypes,
  };
};

const reset = () => _.cloneDeep(initialState);

// @ts-expect-error [🤖 PLEASE FIX 🤖]: Parameter 'state' implicitly has an 'any' type.
const resetSelectedYearRange = (state) => {
  return {
    ...state,
    selectedYearRange: initialState.selectedYearRange,
    uiSelectedYearRange: initialState.selectedYearRange,
  };
};

// @ts-expect-error [🤖 PLEASE FIX 🤖]: Parameter 'state' implicitly has an 'any' type.
const resetSelectedPriceRange = (state) => {
  return {
    ...state,
    selectedPriceRange: initialState.selectedPriceRange,
    uiSelectedPriceRange: initialState.selectedPriceRange,
  };
};

export default {
  addBodyStyle,
  addLifestyle,
  addMake,
  addSafetyFeature,
  addStarRating,
  addPowerType,
  removeBodyStyle,
  removeLifestyle,
  removeMake,
  removeSafetyFeature,
  removeStarRating,
  removePowerType,
  reset,
  resetSelectedPriceRange,
  resetSelectedYearRange,
  updateLifestyles,
  updateMakes,
  updateSafetyFeatures,
  updateSelectedBodyStyles,
  updateSelectedPriceRange,
  updateSelectedYearRange,
  updateStarRatings,
  updatePowerTypes,
  updateUiSelectedPriceRange,
  updateUiSelectedYearRange,
};
