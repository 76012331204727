import PropTypes from 'prop-types';
import React from 'react';

import { COLORS } from '../../constants';
import { iconNames } from '../icons';

import { StyledIcon, Wrapper, StyledWarningIcon } from './styles';

// @ts-expect-error [🤖 PLEASE FIX 🤖]: Binding element 'iconName' implicitly has an 'any' type.
const IconWithBackground = ({ iconName, backgroundColor, className = '', ...props }) => {
  return (
    <Wrapper {...{ backgroundColor, className }}>
      <StyledIcon {...{ name: iconName }} {...props} />
    </Wrapper>
  );
};

IconWithBackground.propTypes = {
  backgroundColor: PropTypes.string.isRequired,
  className: PropTypes.string,
  iconName: PropTypes.string.isRequired,
};

export const iconsWithBackgrounds = {
  // @ts-expect-error [🤖 PLEASE FIX 🤖]: Binding element 'className' implicitly has an 'any' type.
  GreenTick: ({ className, ...props }) => (
    <IconWithBackground
      {...{
        iconName: iconNames.tick,
        backgroundColor: COLORS.SUCCESS_GREEN_NINE,
        'aria-label': 'Success',
        className,
        ...props,
      }}
    />
  ),
  // @ts-expect-error [🤖 PLEASE FIX 🤖]: Binding element 'className' implicitly has an 'any' type.
  BlueTick: ({ className, ...props }) => (
    <IconWithBackground
      {...{
        iconName: iconNames.tick,
        backgroundColor: COLORS.BRAND_NAVY_EIGHT,
        'aria-label': 'Safer Pick',
        className,
        ...props,
      }}
    />
  ),
  // @ts-expect-error [🤖 PLEASE FIX 🤖]: Binding element 'className' implicitly has an 'any' type.
  RedCross: ({ className, ...props }) => (
    <IconWithBackground
      {...{
        iconName: iconNames.cross,
        backgroundColor: COLORS.DANGER_RED_EIGHT,
        className,
        ...props,
      }}
    />
  ),
  // @ts-expect-error [🤖 PLEASE FIX 🤖]: Binding element 'className' implicitly has an 'any' type.
  GreyQuestionMark: ({ className, ...props }) => (
    <IconWithBackground
      {...{
        iconName: iconNames.questionMark,
        backgroundColor: COLORS.GREY_FOUR,
        className,
        ...props,
      }}
    />
  ),
  // @ts-expect-error [🤖 PLEASE FIX 🤖]: Binding element 'className' implicitly has an 'any' type.
  YellowQuestionMark: ({ className, ...props }) => (
    <IconWithBackground
      {...{
        iconName: iconNames.questionMark,
        backgroundColor: COLORS.GREY_FOUR,
        className,
        ...props,
      }}
    />
  ),
  // @ts-expect-error [🤖 PLEASE FIX 🤖]: Binding element 'className' implicitly has an 'any' type.
  GreyPlus: ({ className, ...props }) => {
    return (
      <IconWithBackground
        {...{
          iconName: iconNames.plus,
          backgroundColor: COLORS.GREY_FOUR,
          className,
          ...props,
        }}
      />
    );
  },
  // Since the warning icon already has the background added to it, it can directly use the Icon component
  // @ts-expect-error [🤖 PLEASE FIX 🤖]: Binding element 'className' implicitly has an 'any' type.
  RedWarning: ({ className }) => (
    <StyledWarningIcon {...{ name: iconNames.warning, 'aria-label': 'red warning' }} className={className} />
  ),
};

export default IconWithBackground;
