import _ from 'lodash';

// Ensures that the URL that the user has landed here with is all lowercased.
// @ts-expect-error [🤖 PLEASE FIX 🤖]: Parameter 'url' implicitly has an 'any' type.
const redirectToLowercaseUrl = (url) => {
  const { searchParams, pathname } = new URL(url, 'https://example.com');
  const lowerPathName = _.toLower(pathname);
  const queryString = searchParams.toString();
  const lowercaseUrl = `${lowerPathName}${queryString ? `?${queryString}` : ''}`;
  if (!_.isEmpty(url) && lowerPathName !== pathname) return lowercaseUrl;
  return false;
};

export { redirectToLowercaseUrl };
