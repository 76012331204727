import React from 'react';

// @ts-expect-error [🤖 PLEASE FIX 🤖]: Parameter 'props' implicitly has an 'any' type.
const CloseIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" {...props}>
    <path
      // eslint-disable-next-line react/destructuring-assignment
      fill={props.white ? '#FFF' : '#202020'}
      fillRule="evenodd"
      d="M15.907 2.292a.996.996 0 0 1-.006 1.42l-5.387 5.386 5.387 5.386a.995.995 0 0 1 .089 1.326l-.083.094-.095.084a1.003 1.003 0 0 1-1.325-.09L9.1 10.513l-5.386 5.387c-.363.363-.93.395-1.325.089l-.094-.084a.996.996 0 0 1 .005-1.42l5.386-5.386L2.3 3.712a.995.995 0 0 1-.089-1.326l.084-.094a1.001 1.001 0 0 1 1.42.006L9.1 7.683l5.387-5.385a1.001 1.001 0 0 1 1.42-.006z"
    />
  </svg>
);

export default CloseIcon;
