import _ from 'lodash';

// @ts-expect-error [🤖 PLEASE FIX 🤖]: Parameter 'data' implicitly has an 'any' type.
const setCacheState = (data, isLoading, isError) => ({ isLoading, isError, data });

// @ts-expect-error [🤖 PLEASE FIX 🤖]: Parameter 'state' implicitly has an 'any' type.
const addCache = (state, payload) => {
  return {
    ...state,
    [_.keys(payload)[0]]: setCacheState(_.values(payload)[0], false, false),
  };
};

// @ts-expect-error [🤖 PLEASE FIX 🤖]: Parameter 'state' implicitly has an 'any' type.
const invalidateCache = (state, key) => {
  return {
    ...state,
    [key]: setCacheState(null, true, false),
  };
};

// @ts-expect-error [🤖 PLEASE FIX 🤖]: Parameter 'state' implicitly has an 'any' type.
const errorCache = (state, key) => {
  return {
    ...state,
    [key]: setCacheState(null, false, true),
  };
};

export default { addCache, invalidateCache, errorCache, setCacheState };
