import React from 'react';

// @ts-expect-error [🤖 PLEASE FIX 🤖]: Parameter 'props' implicitly has an 'any' type.
const WarningIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" {...props}>
    <g fill="none" fillRule="evenodd">
      <path
        fill="#FCFCFC"
        fillRule="nonzero"
        d="M13.934 6.699c.337-.362.934-.345 1.332.038.398.382.447.985.11 1.347l-2.721 2.91 2.72 2.91c.338.362.29.965-.109 1.348-.398.382-.995.399-1.332.037l-2.647-2.832-2.646 2.832c-.312.334-.845.345-1.238.044l-.095-.081c-.398-.383-.447-.986-.11-1.347l2.72-2.911-2.72-2.91c-.337-.362-.288-.965.11-1.347.398-.383.995-.4 1.333-.038l2.646 2.831z"
      />
      <circle cx="11" cy="11" r="11" fill="#D92600" />
      <path
        fill="#FFF"
        fillRule="nonzero"
        d="M12.107 14.004l.63-8.766H9.461l.63 8.766h2.016zm-1.026 4.176c.864 0 1.566-.702 1.566-1.548 0-.864-.702-1.566-1.566-1.566-.864 0-1.548.702-1.548 1.566 0 .846.684 1.548 1.548 1.548z"
      />
    </g>
  </svg>
);

export default WarningIcon;
