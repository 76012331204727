import React from 'react';

// @ts-expect-error [🤖 PLEASE FIX 🤖]: Parameter 'props' implicitly has an 'any' type.
const VisibilityOff = (props) => (
  <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" width="32" height="32" {...props}>
    <path d="M16,9.33333333 C19.68,9.33333333 22.6666667,12.32 22.6666667,16 C22.6666667,16.8666667 22.4933333,17.68 22.1866667,18.44 L26.08,22.3333333 C28.0933333,20.6533333 29.68,18.48 30.6533333,16 C28.3466667,10.1466667 22.6533333,6 15.9866667,6 C14.12,6 12.3333333,6.33333333 10.68,6.93333333 L13.56,9.81333333 C14.32,9.50666667 15.1333333,9.33333333 16,9.33333333 Z M2.66666667,5.69333333 L5.70666667,8.73333333 L6.32,9.34666667 C4.10666667,11.0666667 2.37333333,13.36 1.33333333,16 C3.64,21.8533333 9.33333333,26 16,26 C18.0666667,26 20.04,25.6 21.84,24.88 L22.4,25.44 L26.3066667,29.3333333 L28,27.64 L4.36,4 L2.66666667,5.69333333 Z M10.04,13.0666667 L12.1066667,15.1333333 C12.04,15.4133333 12,15.7066667 12,16 C12,18.2133333 13.7866667,20 16,20 C16.2933333,20 16.5866667,19.96 16.8666667,19.8933333 L18.9333333,21.96 C18.04,22.4 17.0533333,22.6666667 16,22.6666667 C12.32,22.6666667 9.33333333,19.68 9.33333333,16 C9.33333333,14.9466667 9.6,13.96 10.04,13.0666667 L10.04,13.0666667 Z M15.7866667,12.0266667 L19.9866667,16.2266667 L20.0133333,16.0133333 C20.0133333,13.8 18.2266667,12.0133333 16.0133333,12.0133333 L15.7866667,12.0266667 Z" />
  </svg>
);

export default VisibilityOff;
