import React from 'react';
import MuiIconButton from '@mui/material/IconButton';

import Icon, { iconNames } from '../icons';

// @ts-expect-error [🤖 PLEASE FIX 🤖]: Binding element 'ariaLabel' implicitly has an 'any' type.
const IconButton = ({ ariaLabel, className, edge, iconName, onClick, name }) => (
  // https://material-ui.com/api/icon-button/#props
  <MuiIconButton edge={edge} aria-label={ariaLabel} {...{ onClick, className, name }}>
    {/* @ts-expect-error [🤖 PLEASE FIX 🤖]: Element implicitly has an 'any' type because expression of type 'any' can't be used to index type 'Record<"filter" | "visibility" | "caret" | "download" | "close" | "compareCar" | "cross" | "expand" | "didYouKnow" | "facebook" | "hamburger" | "instagram" | "linkActive" | "linkDisabled" | ... 11 more ... | "warning", "filter" | ... 24 more ... | "warning">'. */}
    <Icon name={iconNames[iconName]} />
  </MuiIconButton>
);

export { IconButton };
