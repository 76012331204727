import styled from '@emotion/styled';

import { BREAKPOINTS, COLORS, SPACE, FONT_SIZE, LINE_HEIGHT } from '@/constants';
import { Box } from '@mui/system';
import { Small } from '@/components/type';
import { Divider } from '@mui/material';

const TitleDivider = styled(Divider)`
  margin-top: 12px;
  margin-bottom: 12px;
`;

const CardWrapper = styled(Box)`
  padding: 8px;
  max-width: 33.33%;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const CarImageWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const CarImage = styled.img`
  max-width: 100%;
  height: auto;
`;

const ModelDetails = styled.div`
  padding-top: ${SPACE.X0};

  height: 40%;
`;

const ModelYear = styled(Small)`
  color: ${COLORS.GREY_ELEVEN};

  @media (min-width: ${BREAKPOINTS.LG}px) {
    font-size: ${FONT_SIZE.SMALL};
  }
`;

const RecommendedFeaturesSmall = styled(Small)`
  display: inline-block;

  @media (min-width: ${BREAKPOINTS.LG}px) {
    font-size: ${FONT_SIZE.SMALL};
    line-height: ${LINE_HEIGHT.BODY.SMALL};
  }
`;

const RecommendedFeaturesContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  margin-top: ${SPACE.X0};
  margin-bottom: ${SPACE.X0};

  @media (min-width: ${BREAKPOINTS.SM}px) {
    flex-direction: row;
  }
`;

const Price = styled(Small)`
  display: inline-block;

  @media (min-width: ${BREAKPOINTS.LG}px) {
    font-size: ${FONT_SIZE.SMALL};
  }
`;

const PriceAndFeaturesContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export {
  CardWrapper,
  CarImage,
  CarImageWrapper,
  ModelDetails,
  ModelYear,
  RecommendedFeaturesSmall,
  Price,
  TitleDivider,
  RecommendedFeaturesContainer,
  PriceAndFeaturesContainer,
};
