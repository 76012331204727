import _ from 'lodash';

import { RATERS } from '../constants';

export const MAX_ANCAP_AGE = 6;

export const OVERRIDES = {
  ANCAP: 'ancap',
  UCSR: 'ucsr',
  COPRESENTATIONAL: 'useCoPresentationProtocol',
};

// @ts-expect-error [🤖 PLEASE FIX 🤖]: Binding element 'ratingFromAncap' implicitly has an 'any' type.
const getAncapRating = ({ ratingFromAncap, ratingFromRedbook, ratingYear }) => {
  // prefer redbook-sourced ancap rating, then ancap-sourced rating
  const rating = ratingFromRedbook || ratingFromAncap;
  const hasValidRating =
    !_.isNull(rating) && rating > 0 && ratingYear >= new Date(Date.now()).getFullYear() - MAX_ANCAP_AGE;

  return {
    provider: RATERS.ANCAP,
    rating,
    hasValidRating,
  };
};

// @ts-expect-error [🤖 PLEASE FIX 🤖]: Binding element 'ucsrRating' implicitly has an 'any' type.
const getUcsrRating = ({ ucsrRating }) => {
  const hasValidRating = !_.isNull(ucsrRating) && ucsrRating > 0;

  return {
    provider: RATERS.UCSR,
    rating: ucsrRating,
    hasValidRating,
  };
};

// @ts-expect-error [🤖 PLEASE FIX 🤖]: Binding element 'ancap' implicitly has an 'any' type.
const getSelectedRatingProvider = ({ ancap, ucsr, ratingProviderOverride }) => {
  const hasRatingProviderOverride = ratingProviderOverride && ratingProviderOverride !== OVERRIDES.COPRESENTATIONAL;
  const shouldUseAncap = !hasRatingProviderOverride && ancap.hasValidRating;
  const shouldUseUcsr = !hasRatingProviderOverride && ucsr.hasValidRating;

  const ratingProvider = {
    provider: null,
    rating: null,
  };

  if ((ratingProviderOverride === OVERRIDES.ANCAP || shouldUseAncap) && ancap.rating) {
    ratingProvider.provider = ancap.provider;
    ratingProvider.rating = ancap.rating;
  } else if ((ratingProviderOverride === OVERRIDES.UCSR || shouldUseUcsr) && ucsr.rating) {
    ratingProvider.provider = ucsr.provider;
    ratingProvider.rating = ucsr.rating;
  } else if (ancap.rating || ucsr.rating) {
    // if there's an ancap rating but no ucsr rating and the ancap rating is old,
    // or has a rating but the rating provider is not what is being displayed
    // @ts-expect-error [🤖 PLEASE FIX 🤖]: Type 'string' is not assignable to type 'null'.
    ratingProvider.provider = RATERS.NO_CURRENT_RATING;
    // @ts-expect-error [🤖 PLEASE FIX 🤖]: Type '0' is not assignable to type 'null'.
    ratingProvider.rating = 0;
  } else {
    // if there's neither an ancap nor ucsr rating
    // @ts-expect-error [🤖 PLEASE FIX 🤖]: Type 'string' is not assignable to type 'null'.
    ratingProvider.provider = RATERS.NO_RATING;
    // @ts-expect-error [🤖 PLEASE FIX 🤖]: Type '0' is not assignable to type 'null'.
    ratingProvider.rating = 0;
  }

  return ratingProvider;
};

// @ts-expect-error [🤖 PLEASE FIX 🤖]: Parameter 'vehicle' implicitly has an 'any' type.
const getRating = (vehicle, ancapTestRecord) => {
  const { ratingProviderOverride, ancapRating: ratingFromRedbook, ucsrRating } = vehicle;
  const { rating: ratingFromAncap, ratingYear } = ancapTestRecord;

  const ancap = getAncapRating({
    ratingFromAncap,
    ratingFromRedbook,
    ratingYear,
  });

  const ucsr = getUcsrRating({
    ucsrRating,
  });

  const selectedRating = getSelectedRatingProvider({
    ancap,
    ucsr,
    ratingProviderOverride,
  });

  return {
    selectedRating,
    ancap,
    ucsr,
  };
};

export { getRating };
