import { isEmpty } from 'lodash';
import { gql } from '@apollo/client';
import { createApolloClient, apolloAPIErrorHandler } from '../../apollo-api/client';

// @ts-expect-error [🤖 PLEASE FIX 🤖]: Parameter 'vehicleId' implicitly has an 'any' type.
export const getVehicleAncapTestRecords = async (vehicleId) => {
  try {
    const client = createApolloClient();
    const response = await client.query({
      variables: { vehicleId },
      query: gql`
        query getVehicleAncapTestRecords($vehicleId: String!) {
          hsiycGetVehicleAncapTestRecords(vehicleId: $vehicleId)
        }
      `,
    });

    if (isEmpty(response?.data?.hsiycGetVehicleAncapTestRecords)) {
      return { years: [] };
    }

    return JSON.parse(response.data.hsiycGetVehicleAncapTestRecords);
  } catch (err) {
    return apolloAPIErrorHandler(err);
  }
};
