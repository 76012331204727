import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { useTheme } from '@mui/system';
import { useMediaQuery } from '@mui/material';

import { getPriceGuideCopy } from '@/utils/get-relevant-price-for-vehicle';
import { PrimaryButton } from '@/components/button';
import { H5 } from '@/components/type';

import {
  CardWrapper,
  CarImage,
  CarImageWrapper,
  ModelDetails,
  ModelYear,
  RecommendedFeaturesSmall,
  RecommendedFeaturesContainer,
  Price,
  TitleDivider,
  PriceAndFeaturesContainer,
} from './styles';

// @ts-expect-error [🤖 PLEASE FIX 🤖]: Binding element 'hit' implicitly has an 'any' type.
const MinicartListing = ({ hit, addToCompare }) => {
  const theme = useTheme();
  const smUp = useMediaQuery(theme.breakpoints.up('sm'));

  const {
    image = '/images/car-image-not-found.svg',
    make,
    model,
    recommendedSafetyFeatures,
    totalSafetyFeatures,
    year,
    objectID: redbookCode,
  } = hit;

  const handleAddVehicleButton = () => {
    addToCompare(redbookCode);
  };

  const recommendedFeaturesLabel = smUp ? 'Recommended features' : "Rec'd features";

  return (
    <CardWrapper>
      <CarImageWrapper>
        <CarImage src={image} alt={`${year} ${make} ${model}`} />
      </CarImageWrapper>
      <ModelDetails>
        <H5>
          {make} {model}
        </H5>
        <ModelYear>{year}</ModelYear>
        <TitleDivider />
      </ModelDetails>
      <PriceAndFeaturesContainer>
        <Price>{getPriceGuideCopy(hit)}</Price>
        <RecommendedFeaturesContainer>
          <RecommendedFeaturesSmall>{recommendedFeaturesLabel}</RecommendedFeaturesSmall>
          <RecommendedFeaturesSmall>
            <strong>
              {recommendedSafetyFeatures}/{totalSafetyFeatures}
            </strong>
          </RecommendedFeaturesSmall>
        </RecommendedFeaturesContainer>
      </PriceAndFeaturesContainer>
      <PrimaryButton
        fullWidth
        onClick={handleAddVehicleButton}
        aria-label={`Add ${year} ${make} ${model} to comparison`}
      >
        Add vehicle
      </PrimaryButton>
    </CardWrapper>
  );
};

MinicartListing.propTypes = {
  addToCompare: PropTypes.func.isRequired,
};

// @ts-expect-error [🤖 PLEASE FIX 🤖]: Parameter 'dispatch' implicitly has an 'any' type.
const mapDispatch = (dispatch) => ({
  addToCompare: dispatch.compare.addToCompare,
});

export const MinicartListingForTest = MinicartListing;

export default connect(null, mapDispatch)(MinicartListing);
