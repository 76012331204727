import React from 'react';
import PropTypes from 'prop-types';

import { Col, Row } from '../grid';
import RichText from '../rich-text';

import { StyledContainer, StyledH3 } from './styles';

const ContentBlock = ({ heading = null, body = null }) => {
  return (
    // @ts-expect-error [🤖 PLEASE FIX 🤖]: Type '{ children: Element; }' is missing the following properties from type '{ [x: string]: any; className: any; maxWidth: any; children: any; }': className, maxWidth
    <StyledContainer>
      {/* @ts-expect-error [🤖 PLEASE FIX 🤖]: Type '{ children: Element[]; }' is missing the following properties from type '{ [x: string]: any; className: any; children: any; spacing: any; }': className, spacing */}
      <Row>
        {/* @ts-expect-error [🤖 PLEASE FIX 🤖]: Type '{ xs: boolean; lg: number; }' is missing the following properties from type '{ [x: string]: any; className: any; offset: any; width: any; }': className, offset, width */}
        <Col xs={false} lg={1} />
        {/* @ts-expect-error [🤖 PLEASE FIX 🤖]: Type '{ children: null[]; xs: number; lg: number; }' is missing the following properties from type '{ [x: string]: any; className: any; offset: any; width: any; }': className, offset, width */}
        <Col xs={12} lg={8}>
          {heading && <StyledH3 as="h2">{heading}</StyledH3>}
          {body && <RichText>{body}</RichText>}
        </Col>
      </Row>
    </StyledContainer>
  );
};

ContentBlock.query = `
  ... on contentMatrix_contentBlock_BlockType {
    id
    typeHandle
    heading
    body
  }
  `;

ContentBlock.propTypes = {
  heading: PropTypes.string,
  body: PropTypes.string,
};

export default ContentBlock;
