import React from 'react';
import styled from '@emotion/styled';

import { COLORS } from '../../../constants';

const StyledIcon = styled.svg`
  fill: ${COLORS.GREY_ONE};
`;

// @ts-expect-error [🤖 PLEASE FIX 🤖]: Parameter 'props' implicitly has an 'any' type.
const CrossIcon = (props) => (
  <StyledIcon xmlns="http://www.w3.org/2000/svg" width="9" height="10" viewBox="0 0 9 10" {...props}>
    <path d="M6.934.699c.337-.362.934-.345 1.332.038.398.382.447.985.11 1.347l-2.721 2.91 2.72 2.91c.338.362.29.965-.109 1.348-.398.382-.995.399-1.332.037L4.287 6.457 1.641 9.289c-.312.334-.845.345-1.238.044l-.095-.081c-.398-.383-.447-.986-.11-1.347l2.72-2.911-2.72-2.91c-.337-.362-.288-.965.11-1.347.398-.383.995-.4 1.333-.038L4.287 3.53z" />
  </StyledIcon>
);

export default CrossIcon;
