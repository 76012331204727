import { useState } from 'react';

export const useAccordion = () => {
  const [openAccordions, setOpenAccordions] = useState(false);

  // @ts-expect-error [🤖 PLEASE FIX 🤖]: Parameter 'accordionIndex' implicitly has an 'any' type.
  const handleChange = (accordionIndex) => {
    // @ts-expect-error [🤖 PLEASE FIX 🤖]: Element implicitly has an 'any' type because expression of type 'any' can't be used to index type 'Boolean'.
    const isOpen = !!openAccordions[accordionIndex];
    // @ts-expect-error [🤖 PLEASE FIX 🤖]: Spread types may only be created from object types.
    setOpenAccordions({ ...openAccordions, [accordionIndex]: !isOpen });
  };

  return [openAccordions, handleChange];
};
