import React from 'react';
// @ts-expect-error [🤖 PLEASE FIX 🤖]: 'PropTypes' can only be imported by using a default import.
import { PropTypes } from 'prop-types';
import styled from '@emotion/styled';
import _ from 'lodash';

import { COLORS, SPACE } from '../../constants';
import { Container, Row, Col } from '../grid';
import { H2 } from '../type';
import RichText from '../rich-text';
import craftBackend from '../../utils/craft-backend-api';

import ContactForm from './contact-form';

const Wrapper = styled.div`
  background-color: ${COLORS.GREY_ONE};
  color: ${COLORS.GREY_ELEVEN};

  padding: ${
      /* @ts-expect-error [🤖 PLEASE FIX 🤖]: Property 'XL' does not exist on type '{ X0: string; X1: string; X2: string; X3: string; X4: string; }'. */
      SPACE.XL
    }
    0;
`;

const ContentCol = styled(Col)`
  text-align: center;
`;

// @ts-expect-error [🤖 PLEASE FIX 🤖]: Binding element 'setStatus' implicitly has an 'any' type.
async function defaultHandleSubmit({ ...formValues }, { setStatus, setSubmitting, setErrors }) {
  try {
    // @ts-expect-error [🤖 PLEASE FIX 🤖]: Property 'submitForm' does not exist on type 'AxiosInstance'.
    await craftBackend.submitForm('/', formValues);
    setStatus('success');
    setSubmitting(false);
  } catch (error) {
    setErrors({
      // @ts-expect-error [🤖 PLEASE FIX 🤖]: 'error' is of type 'unknown'.
      general: _.isEmpty(error.fieldErrors) ? error.message : null,
      // @ts-expect-error [🤖 PLEASE FIX 🤖]: 'error' is of type 'unknown'.
      ...error.fieldErrors,
    });
    setSubmitting(false);
  }
}

const ContactFormBlock = ({
  body = null,
  subject = null,
  heading = null,
  // @ts-expect-error [🤖 PLEASE FIX 🤖]: Binding element 'successMessage' implicitly has an 'any' type.
  successMessage,
  handleSubmit = defaultHandleSubmit,
  isLoading = false,
}) => {
  return (
    <Wrapper>
      {/* @ts-expect-error [🤖 PLEASE FIX 🤖]: Type '{ children: Element[]; }' is missing the following properties from type '{ [x: string]: any; className: any; maxWidth: any; children: any; }': className, maxWidth */}
      <Container>
        {/* @ts-expect-error [🤖 PLEASE FIX 🤖]: Type '{ children: Element; wrap: string; }' is missing the following properties from type '{ [x: string]: any; className: any; children: any; spacing: any; }': className, spacing */}
        <Row wrap="wrap">
          {/* @ts-expect-error [🤖 PLEASE FIX 🤖]: Property 'className' is missing in type '{ children: null[]; width: { xs: number; lg: number; }; offset: number[]; }' but required in type '{ [x: string]: any; className: any; offset: any; width: any; }'. */}
          <ContentCol width={{ xs: 12, lg: 6 }} offset={[0, 0, 0, 3 / 12]}>
            {heading && <H2>{heading}</H2>}
            {/* @ts-expect-error [🤖 PLEASE FIX 🤖]: Type '{ children: never; keepMargins: true; }' is not assignable to type 'IntrinsicAttributes & Pick<{ children?: null | undefined; className?: null | undefined; }, "className" | "children"> & Pick<InferProps<{ children: Requireable<ReactNodeLike>; className: Requireable<...>; }>, never> & Pick<...>'. */}
            {body && <RichText keepMargins>{body}</RichText>}
          </ContentCol>
        </Row>
        <ContactForm
          // @ts-expect-error [🤖 PLEASE FIX 🤖]: Type '{ subject: null; successMessage: any; handleSubmit: ({ ...formValues }: { [x: string]: any; }, { setStatus, setSubmitting, setErrors }: { setStatus: any; setSubmitting: any; setErrors: any; }) => Promise<void>; isLoading: boolean; }' is not assignable to type 'IntrinsicAttributes & object'.
          subject={subject}
          successMessage={successMessage}
          handleSubmit={handleSubmit}
          isLoading={isLoading}
        />
      </Container>
    </Wrapper>
  );
};

ContactFormBlock.query = `
  ... on contentMatrix_contactFormBlock_BlockType {
    id
    typeHandle
    heading
    body
    subject
    successMessage
  }
  `;

ContactFormBlock.propTypes = {
  body: PropTypes.string,
  handleSubmit: PropTypes.func,
  heading: PropTypes.string,
  subject: PropTypes.string,
  successMessage: PropTypes.string.isRequired,
  isLoading: PropTypes.bool,
};

export default ContactFormBlock;
