import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import styled from '@emotion/styled';

import { COLORS, LOCALSTORAGE_KEYS, URLS, LINKS } from '../../constants';
import Footer from '../footer';
import Header from '../header';
import { iconNames } from '../icons';
import MiniCartWrapper from '../mini-cart-wrapper';
import Loading from '../loading';
import Seo from '../../utils/seo/component';
import { hydrateVehicleIdsStateFromObject } from '../../utils/hydrate-vehicle-ids-state';

const Wrapper = styled.div`
  background-color: ${COLORS.GREY_ONE};
  width: 100%;
  min-height: 100vh;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
`;

const titleSecondary = 'More about';

const footerLinks = {
  termsAndPrivacyLinks: LINKS.TERMS_AND_PRIVACY,
  socialLinks: [
    { iconName: iconNames.facebook, url: URLS.FACEBOOK },
    { iconName: iconNames.instagram, url: URLS.INSTAGRAM },
    { iconName: iconNames.twitter, url: URLS.TWITTER },
  ],
  pageLinks: [...LINKS.PRIMARY, ...LINKS.SECONDARY],
};

// @ts-expect-error [🤖 PLEASE FIX 🤖]: Parameter 'compareVehicleIds' implicitly has an 'any' type.
const hydrateCompareVehicleIds = (compareVehicleIds, actions) => {
  if (!compareVehicleIds || !compareVehicleIds.length) {
    if (typeof window !== 'undefined' && typeof localStorage !== 'undefined') {
      const storageItem = localStorage.getItem(LOCALSTORAGE_KEYS.COMPARE);
      const vehicleIds = storageItem ? JSON.parse(storageItem) : [];
      hydrateVehicleIdsStateFromObject(vehicleIds, actions);
    }
  }
};

const Layout = ({
  // @ts-expect-error [🤖 PLEASE FIX 🤖]: Binding element 'actions' implicitly has an 'any' type.
  actions,
  // @ts-expect-error [🤖 PLEASE FIX 🤖]: Binding element 'children' implicitly has an 'any' type.
  children,
  className = '',
  compareVehicleIds = [],
  // @ts-expect-error [🤖 PLEASE FIX 🤖]: Binding element 'filterActions' implicitly has an 'any' type.
  filterActions,
  isHomePage = false,
  isOpen = false,
}) => {
  const { closeMiniCart, openMiniCart, removeFromCompare } = actions;
  const numOfVehiclesToCompare = _.size(compareVehicleIds);

  hydrateCompareVehicleIds(compareVehicleIds, actions);

  return (
    <Wrapper className={className}>
      <Seo />
      <Loading />
      <MiniCartWrapper {...{ compareVehicleIds, filterActions, isOpen, removeFromCompare }} onClose={closeMiniCart} />
      <Header
        {...{ isHomePage }}
        compareButtonProps={{ count: numOfVehiclesToCompare, onOpenComparison: openMiniCart }}
        primaryNavProps={{ links: LINKS.PRIMARY }}
        // @ts-expect-error [🤖 PLEASE FIX 🤖]: Type '{ title: string; url: string; }[]' is not assignable to type 'never[]'.
        secondaryNavProps={{ links: LINKS.SECONDARY, title: titleSecondary }}
        // @ts-expect-error [🤖 PLEASE FIX 🤖]: Type '{ iconName: "filter" | "visibility" | "caret" | "download" | "close" | "compareCar" | "cross" | "expand" | "didYouKnow" | "facebook" | "hamburger" | "instagram" | "linkActive" | ... 12 more ... | "warning"; url: string; }[]' is not assignable to type 'never[]'.
        socialLinksProps={{ socialIcons: footerLinks.socialLinks, tagline: 'Join the conversation' }}
      />
      {children}
      {/* @ts-expect-error [🤖 PLEASE FIX 🤖]: Type '{ termsAndPrivacyLinks: { title: string; url: string; }[]; socialLinks: { iconName: "filter" | "visibility" | "caret" | "download" | "close" | "compareCar" | "cross" | "expand" | "didYouKnow" | ... 16 more ... | "warning"; url: string; }[]; pageLinks: { ...; }[]; }' is not assignable to type 'Pick<{ termsAndPrivacyLinks?: null | undefined; socialLinks?: null | undefined; pageLinks?: null | undefined; }, "termsAndPrivacyLinks" | "socialLinks" | "pageLinks">'. */}
      <Footer {...footerLinks} />
    </Wrapper>
  );
};

Layout.propTypes = {
  actions: PropTypes.shape({
    addToCompare: PropTypes.func.isRequired,
    closeMiniCart: PropTypes.func.isRequired,
    openMiniCart: PropTypes.func.isRequired,
    removeFromCompare: PropTypes.func.isRequired,
  }).isRequired,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  filterActions: PropTypes.shape({}).isRequired,
  isHomePage: PropTypes.bool,
  isOpen: PropTypes.bool,
  compareVehicleIds: PropTypes.arrayOf(PropTypes.string),
};

// @ts-expect-error [🤖 PLEASE FIX 🤖]: Parameter 'state' implicitly has an 'any' type.
const mapState = (state) => ({
  compareVehicleIds: state.compare.compareVehicleIds,
  isOpen: state.ui.global.isMiniCartOpen,
});

// @ts-expect-error [🤖 PLEASE FIX 🤖]: Parameter 'dispatch' implicitly has an 'any' type.
const mapDispatch = (dispatch) => ({
  actions: {
    addToCompare: dispatch.compare.addToCompare,
    closeMiniCart: dispatch.ui.closeMiniCart,
    openMiniCart: dispatch.ui.openMiniCart,
    removeFromCompare: dispatch.compare.removeFromCompare,
  },
  filterActions: dispatch.filters,
});

export default connect(mapState, mapDispatch)(Layout);
