// @ts-expect-error [🤖 PLEASE FIX 🤖]: Parameter 'state' implicitly has an 'any' type.
const fetchDataReducer = (state, action) => {
  switch (action.type) {
    case 'FETCH_INIT':
      return {
        ...state,
        isLoading: true,
        hasError: false,
        data: {},
      };
    case 'FETCH_SUCCESS':
      return {
        ...state,
        isLoading: false,
        hasError: false,
        data: action.payload,
      };
    case 'FETCH_FAIL':
      return {
        ...state,
        isLoading: false,
        hasError: true,
      };
    default:
      throw new Error(`Unknown action type`);
  }
};

export { fetchDataReducer };
