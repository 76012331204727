import React from 'react';

// @ts-expect-error [🤖 PLEASE FIX 🤖]: Parameter 'props' implicitly has an 'any' type.
const PointerIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="18" viewBox="0 0 16 18" {...props}>
    <path d="M15.19 8.635a1.71 1.71 0 0 0-1.703-1.702 1.61 1.61 0 0 0-.975.311 1.723 1.723 0 0 0-1.578-1.079 1.61 1.61 0 0 0-.976.311 1.723 1.723 0 0 0-1.577-1.08c-.312 0-.602.084-.851.23V1.701c0-.457-.187-.892-.498-1.204A1.75 1.75 0 0 0 5.828 0a1.71 1.71 0 0 0-1.702 1.702v8.78L2.942 9.3a1.727 1.727 0 0 0-2.18-.207c-.394.27-.663.706-.746 1.183-.063.477.062.976.373 1.35l3.508 4.234c1.059 1.266 2.595 2.013 4.255 2.013h2.18a4.85 4.85 0 0 0 3.467-1.432 4.9 4.9 0 0 0 1.432-3.467l-.042-4.338zm-2.035 7.182A4.019 4.019 0 0 1 10.29 17H8.111c-1.39 0-2.72-.622-3.612-1.702l-3.487-4.234a.816.816 0 0 1-.187-.685.891.891 0 0 1 .374-.602.827.827 0 0 1 .498-.146c.228 0 .457.083.623.25l1.411 1.39c.208.208.52.27.789.146a.703.703 0 0 0 .436-.665v-9.05c0-.477.373-.85.85-.85.23 0 .437.082.603.248.166.166.249.374.249.602V7.95c0 .228.187.415.415.415a.416.416 0 0 0 .415-.415V7.1c0-.477.374-.851.851-.851.478 0 .851.374.851.851v1.62a.416.416 0 0 0 .83 0v-.852c0-.477.374-.851.852-.851.477 0 .85.374.85.851v1.62a.416.416 0 0 0 .83 0l.001-.79v-.062c0-.477.374-.851.851-.851.478 0 .851.374.851.851v4.338c.083 1.059-.332 2.076-1.1 2.844z" />
  </svg>
);

export default PointerIcon;
