import React from 'react';

// @ts-expect-error [🤖 PLEASE FIX 🤖]: Parameter 'props' implicitly has an 'any' type.
const CompareCarIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="17" viewBox="0 0 20 17" {...props}>
    <path
      fill="#1E1E1E"
      fillRule="nonzero"
      d="M18.98 4.423h-.386c-.44.021-.856.211-1.166.53-.49-1.27-1.124-2.7-1.51-3.282-.696-1.02-1.71-1.306-2.413-1.43A19.777 19.777 0 0 0 10 .005a19.777 19.777 0 0 0-3.5.231c-.702.125-1.717.417-2.413 1.431-.392.575-1.02 2.008-1.514 3.27-.31-.32-.726-.51-1.166-.531h-.385A1.043 1.043 0 0 0 0 5.447v.756c.003.596.472 1.08 1.055 1.092h.508c-.053.07-.103.14-.15.208a4.533 4.533 0 0 0-.72 2.584v5.09c-.001.283.107.556.302.757.195.201.46.315.738.316h1.349c.576 0 1.043-.476 1.043-1.064v-.81h11.748v.8c-.003.283.106.557.302.759.196.201.463.315.741.315h1.35c.575 0 1.042-.476 1.042-1.064v-5.099a4.532 4.532 0 0 0-.724-2.584 3.11 3.11 0 0 0-.152-.208h.509A1.087 1.087 0 0 0 20 6.218v-.756a1.043 1.043 0 0 0-1.02-1.04zM6.083 10.627h-1.91a.825.825 0 0 1-.817-.833v-.506a.839.839 0 0 1 .32-.654c.199-.156.458-.21.701-.146l1.91.51c.38.12.621.5.57.902a.823.823 0 0 1-.774.727zm10.55-.833c0 .46-.365.833-.816.833h-1.904a.823.823 0 0 1-.775-.727.834.834 0 0 1 .57-.902l1.911-.51a.804.804 0 0 1 .701.146c.2.156.317.397.32.654l-.007.506z"
    />
  </svg>
);

export default CompareCarIcon;
