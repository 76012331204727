import _ from 'lodash';

import { priceRangeSliderMarks } from '../../../components/discovery-questions-input/utils/price-range-slider-marks';
import { yearSliderMarks } from '../../../components/discovery-filter/constants';

export default {
  selectedBodyStyles: [],
  selectedLifestyles: [],
  selectedMakes: [],
  selectedStarRatings: [],
  selectedSafetyFeatures: [],
  selectedPowerTypes: [],
  // @ts-expect-error [🤖 PLEASE FIX 🤖]: Object is possibly 'undefined'.
  selectedPriceRange: [_.first(priceRangeSliderMarks).value, _.last(priceRangeSliderMarks).value],
  // @ts-expect-error [🤖 PLEASE FIX 🤖]: Object is possibly 'undefined'.
  uiSelectedPriceRange: [_.first(priceRangeSliderMarks).value, _.last(priceRangeSliderMarks).value],
  // @ts-expect-error [🤖 PLEASE FIX 🤖]: Object is possibly 'undefined'.
  selectedYearRange: [_.first(yearSliderMarks).value, _.last(yearSliderMarks).value],
  // @ts-expect-error [🤖 PLEASE FIX 🤖]: Object is possibly 'undefined'.
  uiSelectedYearRange: [_.first(yearSliderMarks).value, _.last(yearSliderMarks).value],
};
