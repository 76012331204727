import _ from 'lodash';

// @ts-expect-error [🤖 PLEASE FIX 🤖]: Parameter 'vehicles' implicitly has an 'any' type.
export const cutOutDuplicateMakeModels = (vehicles) => {
  const vehicleGroups = {};
  // @ts-expect-error [🤖 PLEASE FIX 🤖]: Variable 'trimmedVehicles' implicitly has type 'any[]' in some locations where its type cannot be determined.
  const trimmedVehicles = [];
  _.forEach(vehicles, (vehicle) => {
    const key = `${vehicle.model}_${vehicle.make}`;
    // @ts-expect-error [🤖 PLEASE FIX 🤖]: Element implicitly has an 'any' type because expression of type 'string' can't be used to index type '{}'.
    vehicleGroups[key] = vehicleGroups[key] || [];
    // @ts-expect-error [🤖 PLEASE FIX 🤖]: Element implicitly has an 'any' type because expression of type 'string' can't be used to index type '{}'.
    vehicleGroups[key].push(vehicle);
  });

  _.forEach(vehicleGroups, (group) => {
    // @ts-expect-error [🤖 PLEASE FIX 🤖]: Property 'length' does not exist on type 'never'.
    const pick = group.length > 1 ? _.last(_.sortBy(group, ['year'])) : _.head(group);
    trimmedVehicles.push(pick);
  });

  // @ts-expect-error [🤖 PLEASE FIX 🤖]: Variable 'trimmedVehicles' implicitly has an 'any[]' type.
  return trimmedVehicles;
};
