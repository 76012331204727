import _ from 'lodash';

import objectToArrayWithKeys from '../object-to-array-with-keys';

// @ts-expect-error [🤖 PLEASE FIX 🤖]: Parameter 'seomaticData' implicitly has an 'any' type.
const extractSeoData = (seomaticData) => {
  const { metaTitleContainer, metaTagContainer, metaLinkContainer, metaJsonLdContainer } = seomaticData || {};

  const title = _.get(metaTitleContainer, 'title.title', '');
  const additionalMetaTags = objectToArrayWithKeys(metaTagContainer);
  const additionalLinkTags = objectToArrayWithKeys(metaLinkContainer);
  const jsonLinkedData = objectToArrayWithKeys(metaJsonLdContainer);

  // hreflang needs to be hrefLang to make it a React prop.
  _.forEach(additionalLinkTags, (additionalLinkTag) => {
    const { data } = additionalLinkTag;
    if (data.hreflang !== undefined) {
      data.hrefLang = data.hreflang;
      delete data.hreflang;
    }
  });

  return { title, additionalMetaTags, additionalLinkTags, jsonLinkedData };
};

export default extractSeoData;
