import _ from 'lodash';

// @ts-expect-error [🤖 PLEASE FIX 🤖]: Parameter 'state' implicitly has an 'any' type.
const openMiniCart = (state) => {
  return _.assign({}, state, {
    global: {
      isMiniCartOpen: true,
    },
  });
};

// @ts-expect-error [🤖 PLEASE FIX 🤖]: Parameter 'state' implicitly has an 'any' type.
const closeMiniCart = (state) => {
  return _.assign({}, state, {
    global: {
      isMiniCartOpen: false,
    },
  });
};

export default { openMiniCart, closeMiniCart };
