import algoliasearch from 'algoliasearch';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { Configure, InstantSearch } from 'react-instantsearch-dom';
import { CARDS_TO_FETCH } from '@/components/similar-but-safer/constants';
import { buildQuery } from '@/components/similar-but-safer/utils/build-query';

const searchClient = algoliasearch(
  // @ts-expect-error [🤖 PLEASE FIX 🤖]: Argument of type 'string | undefined' is not assignable to parameter of type 'string'.
  process.env.NEXT_PUBLIC_ALGOLIA_APPLICATION_ID,
  process.env.NEXT_PUBLIC_ALGOLIA_SEARCH_API_KEY
);

/*
 We want at least 4 cards.
 This may include the current car, 1 per year that we're displaying. We will cut these out except the latest one.
 So we need the amount of results retrieved to cater for this many cars being cut out, while still always giving us at least enough cards.
 We'll trim any extras down to the right amount when they come in from Algolia.
 */
const HITS_TO_RETRIEVE_FROM_ALGOLIA = CARDS_TO_FETCH;

const SimilarButSaferAlgoliaWrapper = ({ vehicle = null, ConnectedComponent = () => null }) => {
  if (vehicle === null) {
    return null;
  }

  const vehicleSafetyFeaturesCount = {
    // @ts-expect-error [🤖 PLEASE FIX 🤖]: Property 'safetyFeaturesLinkedCount' does not exist on type 'never'.
    vehicle: vehicle.safetyFeaturesLinkedCount,
    total: 10,
  };

  // @ts-expect-error [🤖 PLEASE FIX 🤖]: Property 'ancapRating' does not exist on type 'never'.
  const ancapTestRecordRating = vehicle.ancapRating;

  // Do not show SBS if the current vehicle is not rated
  // @ts-expect-error [🤖 PLEASE FIX 🤖]: Property 'ancapRating' does not exist on type 'never'.
  const shouldShow = vehicle.ancapRating || vehicle.ucsrRating || ancapTestRecordRating;
  return (
    <>
      {shouldShow && (
        // @ts-expect-error [🤖 PLEASE FIX 🤖]: No overload matches this call.
        <InstantSearch indexName={process.env.NEXT_PUBLIC_ALGOLIA_INDEX_NAME} searchClient={searchClient}>
          <Configure
            filters={buildQuery(vehicle, vehicleSafetyFeaturesCount, ancapTestRecordRating)}
            hitsPerPage={HITS_TO_RETRIEVE_FROM_ALGOLIA}
          />
          {/* @ts-expect-error [🤖 PLEASE FIX 🤖]: Type '{ makeDescription: any; modelDescription: any; year: any; }' has no properties in common with type 'IntrinsicAttributes'. */}
          <ConnectedComponent
            {...{
              // @ts-expect-error [🤖 PLEASE FIX 🤖]: Property 'makeDescription' does not exist on type 'never'.
              makeDescription: vehicle.makeDescription,
              // @ts-expect-error [🤖 PLEASE FIX 🤖]: Property 'modelDescription' does not exist on type 'never'.
              modelDescription: vehicle.modelDescription,
              // @ts-expect-error [🤖 PLEASE FIX 🤖]: Property 'year' does not exist on type 'never'.
              year: vehicle.year,
            }}
          />
        </InstantSearch>
      )}
    </>
  );
};

SimilarButSaferAlgoliaWrapper.propTypes = {
  vehicle: PropTypes.shape({
    ancapRating: PropTypes.number,
    badgeDescription: PropTypes.string,
    derivedBodyStyle: PropTypes.string,
    engineDisplacement: PropTypes.string,
    id: PropTypes.string,
    image: PropTypes.string,
    imageOverride: PropTypes.array,
    isBaseOption: PropTypes.string,
    makeDescription: PropTypes.string,
    modelDescription: PropTypes.string,
    newPrice: PropTypes.number,
    pPlateStatus: PropTypes.string,
    privateMaxPrice: PropTypes.number,
    privateMinPrice: PropTypes.number,
    redbookCode: PropTypes.string,
    sequenceNumber: PropTypes.number,
    segment: PropTypes.string,
    series: PropTypes.string,
    slug: PropTypes.string,
    transmission: PropTypes.string,
    ucsrAggressivity: PropTypes.number,
    ucsrPrimarySafety: PropTypes.number,
    ucsrRating: PropTypes.number,
    ucsrSaferPick: PropTypes.bool,
    vehicleDescription: PropTypes.string,
    year: PropTypes.string,
  }),
  vehicleSafetyFeaturesCount: PropTypes.shape({}),
  ancapTestRecordRating: PropTypes.number,
};

export default SimilarButSaferAlgoliaWrapper;
