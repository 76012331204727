import _ from 'lodash';
import PropTypes from 'prop-types';

export const GRAPHQL_IMAGE_FIELDS = `
  image: redbookImage,
  imageOverride {
    url
  },
`;

const isEveryValueANonEmptyString = (values = []) => _.every(values, (value) => !_.isEmpty(value) && _.isString(value));

// @ts-expect-error [🤖 PLEASE FIX 🤖]: Parameter 'url' implicitly has an 'any' type.
const overrideImageUrlDomain = (url) => {
  if (
    !isEveryValueANonEmptyString([
      // @ts-expect-error [🤖 PLEASE FIX 🤖]: Type 'string | undefined' is not assignable to type 'never'.
      process.env.VEHICLE_IMAGE_DOMAIN_OVERRIDE_FROM,
      // @ts-expect-error [🤖 PLEASE FIX 🤖]: Type 'string | undefined' is not assignable to type 'never'.
      process.env.VEHICLE_IMAGE_DOMAIN_OVERRIDE_TO,
      // @ts-expect-error [🤖 PLEASE FIX 🤖]: Type 'any' is not assignable to type 'never'.
      url,
    ])
  ) {
    return url;
  }

  // @ts-expect-error [🤖 PLEASE FIX 🤖]: Argument of type 'string | undefined' is not assignable to parameter of type 'string | RegExp'.
  return _.replace(url, process.env.VEHICLE_IMAGE_DOMAIN_OVERRIDE_FROM, process.env.VEHICLE_IMAGE_DOMAIN_OVERRIDE_TO);
};

// @ts-expect-error [🤖 PLEASE FIX 🤖]: Parameter 'vehicle' implicitly has an 'any' type.
export const getVehicleImage = (vehicle) => {
  const matchingPath = _.find(['imageOverride[0].url', 'image.url', 'image'], (path) => {
    const value = _.get(vehicle, path);
    return !_.isEmpty(value) && _.isString(value);
  });

  return {
    // @ts-expect-error [🤖 PLEASE FIX 🤖]: No overload matches this call.
    src: overrideImageUrlDomain(_.get(vehicle, matchingPath)),
    alt: `${_.get(vehicle, 'year', '')}
          ${_.get(vehicle, 'makeDescription', '')}
          ${_.get(vehicle, 'modelDescription', '')}`,
  };
};

// @ts-expect-error [🤖 PLEASE FIX 🤖]: Parameter 'vehicles' implicitly has an 'any' type.
export const getVehicleImages = (vehicles) => {
  return _.map(vehicles, (vehicle) => {
    const vehicleImage = getVehicleImage(vehicle);
    return {
      ...vehicle,
      image: vehicleImage?.src,
      alt: vehicleImage?.alt,
    };
  });
};

export const vehicleImagePropTypes = {
  image: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.shape({
        url: PropTypes.string,
      })
    ),
    PropTypes.string,
  ]),
  imageOverride: PropTypes.arrayOf(
    PropTypes.shape({
      url: PropTypes.string,
    })
  ),
};
