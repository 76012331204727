const STAR_PLACEMENT = {
  SAFETY_ROW: 'safety-row',
  CAR_CARD: 'car-card',
  SCORECARD: 'scorecard',
  COMPARISON_CARD: 'comparison-card',
  COMPARISON_TILE: 'comparison-tile',
  COMPARISON_CARD_ECO_DATA: 'comparison-card-eco-data',
};

export { STAR_PLACEMENT };
