import { includes, inRange, map, values } from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import Image from 'next/image';

import Icon, { iconNames } from '../icons';
import { RATERS, COLORS } from '../../constants';
import { ExtraSmall } from '../type';
import Flex from '../grid/flex';
import AncapLogo from '../../public/images/ancap-logo.svg';
import UcsrLogo from '../../public/images/ucsr-logo.svg';
import { shouldForwardProp } from '../../utils/should-forward-prop';

import {
  LogoImageWrapper,
  StarRatingWrapper,
  StarsWrapper,
  Stars,
  RatingLabelWrapper,
  AncapTestYearLabel,
  AncapTestYear,
  OverallSafetyLabel,
} from './styles';
import { STAR_PLACEMENT } from './constants';

const LOGO_IMAGE_BY_RATER = {
  [RATERS.ANCAP]: {
    src: AncapLogo,
    alt: 'rated by ANCAP',
  },
  [RATERS.UCSR]: {
    src: UcsrLogo,
    alt: 'rated by UCSR',
  },
};

const StarRating = ({
  stars = null,
  ratingBy = null,
  // @ts-expect-error [🤖 PLEASE FIX 🤖]: Binding element 'placement' implicitly has an 'any' type.
  placement,
  className = null,
  forceZeroStars = false,
  testYear = undefined,
  starColor = undefined,
  maxStars = 5,
  starAriaDescription = undefined,
}) => {
  // @ts-expect-error [🤖 PLEASE FIX 🤖]: Argument of type 'null' is not assignable to parameter of type 'string'.
  const noProvider = [RATERS.NO_RATING, RATERS.NO_CURRENT_RATING].includes(ratingBy);
  // @ts-expect-error [🤖 PLEASE FIX 🤖]: Argument of type 'null' is not assignable to parameter of type 'number'.
  const validStars = inRange(stars, 1, maxStars + 1);
  const starsArray = [];
  for (let i = 1; i <= maxStars; i++) {
    if (validStars) {
      // @ts-expect-error [🤖 PLEASE FIX 🤖]: 'stars' is possibly 'null'.
      if (stars >= i) {
        starsArray.push(iconNames.star);
      } else {
        starsArray.push(iconNames.starDefault);
      }
    } else {
      starsArray.push(iconNames.starOutline);
    }
  }

  const isNotRated = includes([RATERS.NO_CURRENT_RATING, RATERS.NO_RATING], ratingBy);

  const showRatingProviderLogo =
    ratingBy && !noProvider && (validStars || forceZeroStars) && placement !== STAR_PLACEMENT.SAFETY_ROW;

  const hasAlternateStyles =
    isNotRated || placement === STAR_PLACEMENT.SAFETY_ROW || placement === STAR_PLACEMENT.COMPARISON_CARD_ECO_DATA;

  return (
    <Flex
      flexDirection="column"
      alignItems={hasAlternateStyles ? 'flex-start' : 'center'}
      maxWidth="100%"
      role="img"
      aria-label={`${starAriaDescription ?? ratingBy} - ${stars} out of ${maxStars} stars`}
      tabIndex="0"
      className={className}
    >
      {showRatingProviderLogo && (
        // @ts-expect-error [🤖 PLEASE FIX 🤖]: Type '{ children: Element; ratingBy: never; }' is not assignable to type 'IntrinsicAttributes & Pick<{}, never> & { theme?: Theme | undefined; } & { ref?: Ref<Component<{}, any, any>> | undefined; }'.
        <LogoImageWrapper ratingBy={ratingBy}>
          <Image
            src={LOGO_IMAGE_BY_RATER[ratingBy].src}
            alt={LOGO_IMAGE_BY_RATER[ratingBy].alt}
            // @ts-expect-error [🤖 PLEASE FIX 🤖]: Type '{ src: any; alt: string; placement: any; layout: string; }' is not assignable to type 'IntrinsicAttributes & Omit<DetailedHTMLProps<ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>, "height" | ... 5 more ... | "srcSet"> & { ...; } & RefAttributes<...>'.
            placement={placement}
            layout="responsive"
          />
        </LogoImageWrapper>
      )}
      <StarRatingWrapper ratingBy={ratingBy}>
        {!includes(
          [STAR_PLACEMENT.COMPARISON_TILE, STAR_PLACEMENT.COMPARISON_CARD, STAR_PLACEMENT.SAFETY_ROW],
          placement
        ) && <RatingByProvider ratingBy={ratingBy} testYear={testYear} />}
        {stars === 0 && forceZeroStars ? (
          <ExtraSmall as="span">ZERO STARS</ExtraSmall>
        ) : (
          <StarsWrapper
            // @ts-expect-error [🤖 PLEASE FIX 🤖]: Type '{ children: Element[]; showBorder: boolean; noPadding: boolean; ratingBy: null; isNotRated: boolean; placement: any; }' is not assignable to type 'IntrinsicAttributes & { theme?: Theme | undefined; as?: ElementType<any, keyof IntrinsicElements> | undefined; } & ClassAttributes<...> & HTMLAttributes<...>'.
            showBorder={ratingBy === RATERS.ANCAP}
            noPadding={hasAlternateStyles}
            ratingBy={ratingBy}
            isNotRated={isNotRated}
            placement={placement}
          >
            {map(starsArray, (star, index) => (
              // @ts-expect-error [🤖 PLEASE FIX 🤖]: Type '{ children: Element; isNotRated: boolean; placement: any; key: number; }' is not assignable to type 'IntrinsicAttributes & { theme?: Theme | undefined; as?: ElementType<any, keyof IntrinsicElements> | undefined; } & ClassAttributes<...> & HTMLAttributes<...>'.
              <Stars isNotRated={isNotRated} placement={placement} key={index}>
                {/* @ts-expect-error [🤖 PLEASE FIX 🤖]: Type 'string' is not assignable to type '"filter" | "visibility" | "caret" | "download" | "close" | "compareCar" | "cross" | "expand" | "didYouKnow" | "facebook" | "hamburger" | "instagram" | "linkActive" | "linkDisabled" | ... 11 more ... | "warning"'. */}
                <StyledIcon ratingBy={ratingBy} name={star} color={starColor} />
              </Stars>
            ))}
          </StarsWrapper>
        )}
      </StarRatingWrapper>
    </Flex>
  );
};

// @ts-expect-error [🤖 PLEASE FIX 🤖]: No overload matches this call.
const StyledIcon = styled(Icon, shouldForwardProp)`
  color: ${
    /* @ts-expect-error [🤖 PLEASE FIX 🤖]: Property 'ratingBy' does not exist on type 'Pick<IconProps, "string" | "clipPath" | "color" | "cursor" | "direction" | "display" | "filter" | "fontFamily" | "fontSize" | "fontSizeAdjust" | "fontStretch" | "fontStyle" | ... 471 more ... | "ariaLabel"> & { ...; } & { ...; }'. */
    ({ ratingBy, color }) => {
      if (color) {
        return color;
      }

      if (ratingBy === RATERS.UCSR) {
        return COLORS.BRAND_BLUE_TWELVE;
      }

      return COLORS.BLACK;
    }
  };
`;

// @ts-expect-error [🤖 PLEASE FIX 🤖]: Binding element 'ratingBy' implicitly has an 'any' type.
const RatingByProvider = ({ ratingBy, testYear }) => {
  if (ratingBy === RATERS.ANCAP && testYear) {
    return (
      <RatingLabelWrapper ratingBy={ratingBy}>
        <AncapTestYearLabel>Tested</AncapTestYearLabel>
        <AncapTestYear>{testYear}</AncapTestYear>
      </RatingLabelWrapper>
    );
  }
  if (ratingBy === RATERS.UCSR) {
    return (
      <RatingLabelWrapper ratingBy={ratingBy}>
        <OverallSafetyLabel>Overall Safety</OverallSafetyLabel>
      </RatingLabelWrapper>
    );
  }
  return null;
};

StarRating.propTypes = {
  placement: PropTypes.oneOf(values(STAR_PLACEMENT)).isRequired,
  ratingBy: PropTypes.oneOf(values(RATERS)),
  stars: PropTypes.number,
  className: PropTypes.string,
  forceZeroStars: PropTypes.bool,
  testYear: PropTypes.number,
  starColor: PropTypes.string,
  starAriaDescription: PropTypes.string,
};

export default StarRating;
