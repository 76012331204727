import { useEffect, useRef, useState } from 'react';

import fetchVehicleByRego from '../../utils/fetch-vehicle-by-rego';
import { logTrackingEvent } from '../../utils/tracking';
import { navigateToVehiclePage } from '../../utils/api';

const useLookupNumberPlate = () => {
  const [numberPlateNotFound, setNumberPlateNotFound] = useState(false);

  const submittingNumberPlate = useRef(false);

  const isMounted = useRef(true);

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  // @ts-expect-error [🤖 PLEASE FIX 🤖]: Parameter 'numberPlate' implicitly has an 'any' type.
  const onSubmitNumberPlate = async (numberPlate) => {
    if (numberPlate) {
      try {
        logTrackingEvent({
          event: 'searchRego',
          numberPlate,
        });

        submittingNumberPlate.current = true;

        const regoLookupResponse = await fetchVehicleByRego(numberPlate);

        submittingNumberPlate.current = false;

        // @ts-expect-error [🤖 PLEASE FIX 🤖]: Property 'data' does not exist on type 'false | AxiosResponse<any>'.
        const { make, model, year, variant, error } = regoLookupResponse.data;

        if (!error && make && model) {
          setNumberPlateNotFound(false);

          navigateToVehiclePage(make, model, year, variant);
        } else {
          setNumberPlateNotFound(true);
        }
      } catch (e) {
        submittingNumberPlate.current = false;

        if (isMounted.current) {
          setNumberPlateNotFound(true);
        }
      }
    }
  };

  return { numberPlateNotFound, onSubmitNumberPlate, setNumberPlateNotFound, submittingNumberPlate };
};

export { useLookupNumberPlate };
