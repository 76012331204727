import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import styled from '@emotion/styled';
import Link from 'next/link';
import { useRouter } from 'next/router';

import ActiveIcon from '../../assets/icons/active.svg';
import DisabledIcon from '../../assets/icons/disabled.svg';
import { COLORS, BREAKPOINTS, FONT_SIZE, GLOBAL, SPACE } from '../../constants';
import { shouldForwardProp } from '../../utils/should-forward-prop';

const StyledNavTitle = styled.div`
  font-size: ${FONT_SIZE.LARGE};
  font-family: ${GLOBAL.BRAND_FONT_FAMILY_SECOND};
  margin-bottom: ${SPACE.X2};
  line-height: 26px;
`;

const StyledNavLinks = styled.ul`
  display: flex;
  flex-direction: column;
  list-style-type: none;
  margin: 0;
  padding-left: 0;

  @media (min-width: ${BREAKPOINTS.MD}px) {
    justify-content: flex-end;
    flex-direction: row;
  }
`;

const StyledLinkWrapper = styled('li', { shouldForwardProp })`
  user-select: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent; /* For some Androids */

  margin-bottom: 0;

  @media (min-width: ${BREAKPOINTS.MD}px) {
    margin-right: ${SPACE.X1};
  }

  @media (min-width: ${BREAKPOINTS.LG}px) {
    margin-right: calc(${SPACE.X1} + ${SPACE.X0});
  }
`;

export const StyledLink = styled('a', { shouldForwardProp })`
  color: ${COLORS.GREY_ELEVEN};
  cursor: pointer;
  display: flex;
  align-items: center;
  font-family: ${GLOBAL.BRAND_FONT_FAMILY};

  font-size: ${
    /* @ts-expect-error [🤖 PLEASE FIX 🤖]: Property 'isSecondary' does not exist on type '{ theme?: Theme | undefined; as?: ElementType<any, keyof IntrinsicElements> | undefined; } & ClassAttributes<HTMLAnchorElement> & AnchorHTMLAttributes<...> & { ...; }'. */
    (props) => (props.isSecondary ? `${FONT_SIZE.XX_LARGE}` : `${FONT_SIZE.XXX_LARGE}`)
  };
  transition: 0.1s ease-in all;
  user-select: none;
  width: 100%;

  ::after {
    content: url(${
      /* @ts-expect-error [🤖 PLEASE FIX 🤖]: Property 'isActive' does not exist on type '{ theme?: Theme | undefined; as?: ElementType<any, keyof IntrinsicElements> | undefined; } & ClassAttributes<HTMLAnchorElement> & AnchorHTMLAttributes<...> & { ...; }'. */
      (props) => (props.isActive ? DisabledIcon : ActiveIcon)
    });
    margin-left: auto;
    padding-bottom: ${SPACE.X1};
  }

  @media (min-width: ${BREAKPOINTS.MD}px) {
    font-family: ${GLOBAL.BRAND_FONT_FAMILY_SECOND};
    font-size: ${FONT_SIZE.LARGE};
    ::after {
      content: none;
    }
  }

  @media (min-width: ${BREAKPOINTS.LG}px) {
    font-size: ${FONT_SIZE.X_LARGE};
  }
`;

const StyledLinkTitle = styled('span', { shouldForwardProp })`
  border-bottom: ${(props) =>
    // @ts-expect-error [🤖 PLEASE FIX 🤖]: Property 'isSecondary' does not exist on type '{ theme?: Theme | undefined; as?: ElementType<any, keyof IntrinsicElements> | undefined; } & ClassAttributes<HTMLSpanElement> & HTMLAttributes<...> & { ...; }'.
    `${props.isSecondary ? '3px' : '4px'} solid ${props.isActive ? COLORS.BRAND_BLUE_SIX : 'transparent'} `};
  line-height: ${GLOBAL.HEADER_HEIGHT[0]};

  @media (min-width: ${BREAKPOINTS.MD}px) {
    line-height: ${GLOBAL.HEADER_HEIGHT[1]};
  }

  @media (min-width: ${BREAKPOINTS.LG}px) {
    line-height: ${GLOBAL.HEADER_HEIGHT[2]};
  }
`;

// @ts-expect-error [🤖 PLEASE FIX 🤖]: Binding element 'navigationProps' implicitly has an 'any' type.
export const NavLinks = ({ navigationProps, isSecondary = false }) => {
  const { links, navTitle } = navigationProps;

  const router = useRouter();
  // @ts-expect-error [🤖 PLEASE FIX 🤖]: Parameter 'url' implicitly has an 'any' type.
  const getIsActive = (url) => router.pathname === url;

  return (
    <>
      {navTitle && <StyledNavTitle>{navTitle}</StyledNavTitle>}
      <StyledNavLinks>
        {_.map(links, ({ title, url }) => (
          // @ts-expect-error [🤖 PLEASE FIX 🤖]: Type '{ children: Element; isActive: boolean; isSecondary: boolean; key: any; }' is not assignable to type 'IntrinsicAttributes & { theme?: Theme | undefined; as?: ElementType<any, keyof IntrinsicElements> | undefined; } & ClassAttributes<...> & LiHTMLAttributes<...>'.
          <StyledLinkWrapper key={title} {...{ isSecondary }} isActive={getIsActive(url)}>
            {/* @ts-expect-error [🤖 PLEASE FIX 🤖]: Type '{ children: Element; isActive: boolean; isSecondary: boolean; href: any; }' is not assignable to type 'IntrinsicAttributes & { theme?: Theme | undefined; as?: ElementType<any, keyof IntrinsicElements> | undefined; } & ClassAttributes<...> & AnchorHTMLAttributes<...>'. */}
            <StyledLink href={url} {...{ isSecondary }} isActive={getIsActive(url)}>
              {/* @ts-expect-error [🤖 PLEASE FIX 🤖]: Type '{ children: any; isActive: boolean; }' is not assignable to type 'IntrinsicAttributes & { theme?: Theme | undefined; as?: ElementType<any, keyof IntrinsicElements> | undefined; } & ClassAttributes<...> & HTMLAttributes<...>'. */}
              <StyledLinkTitle isActive={getIsActive(url)}>{title}</StyledLinkTitle>
            </StyledLink>
          </StyledLinkWrapper>
        ))}
      </StyledNavLinks>
    </>
  );
};

NavLinks.propTypes = {
  navigationProps: PropTypes.shape({
    links: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string,
        url: PropTypes.string,
      })
    ),
    navTitle: PropTypes.string,
  }).isRequired,
  isSecondary: PropTypes.bool,
};

export default NavLinks;
