import React from 'react';

import Box from './box';

// @ts-expect-error [🤖 PLEASE FIX 🤖]: Binding element 'children' implicitly has an 'any' type.
const Flex = ({ children, className, ...props }) => (
  <Box {...props} className={className} display="flex">
    {children}
  </Box>
);

export default Flex;
