import React from 'react';

// @ts-expect-error [🤖 PLEASE FIX 🤖]: Parameter 'props' implicitly has an 'any' type.
const SafetyFeatureFallbackIcon = (props) => (
  <svg width="44px" height="44px" viewBox="0 0 44 44" {...props}>
    <defs>
      <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="linearGradient-1">
        <stop stopColor="#FFD414" offset="14.4425676%" />
        <stop stopColor="#D46511" offset="100%" />
      </linearGradient>
    </defs>
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(-24.000000, -24.000000)">
        <g>
          <g transform="translate(24.000000, 24.000000)">
            <rect fill="#FFFFB5" x="0" y="0" width="44" height="44" rx="10" />
            <g transform="translate(11.000000, 11.000000)" fill="url(#linearGradient-1)" fillRule="nonzero">
              <path d="M11.52,14.4384 C13.0048,14.4384 14.208,13.2352 14.208,11.7504 C14.208,10.2656 13.0048,9.0624 11.52,9.0624 C10.0352,9.0624 8.832,10.2656 8.832,11.7504 C8.832,13.2352 10.0352,14.4384 11.52,14.4384 Z M18.7392,12.8768 C17.7664,13.1072 16.4352,13.9264 15.0784,15.2832 C14.08,16.2816 13.184,17.792 13.184,20.1728 C13.2096,23.0144 20.992,17.6128 20.864,13.7216 C20.8384,13.2352 20.0704,12.5696 18.7392,12.8768 Z M3.6352,9.9584 C5.4784,9.6256 8.576,8.0384 11.6736,8.0384 C13.8496,8.0384 16.1792,9.0624 17.7408,9.5232 C18.2784,9.6768 19.0464,9.9584 20.1472,10.0096 C20.5568,10.0352 20.9664,9.9328 20.9408,9.5744 C20.7872,7.04 17.5104,1.9968 11.52,1.9968 C6.9632,1.9968 2.5088,5.8112 2.1504,9.6256 C2.0992,10.0864 3.0464,10.0352 3.6352,9.9584 Z M9.9072,20.1728 C9.9072,17.792 9.0112,16.2816 8.0128,15.2832 C6.656,13.952 5.3504,13.1072 4.352,12.9024 C3.0208,12.5952 2.2528,13.2352 2.2272,13.7216 C2.0992,17.6128 9.9072,23.0144 9.9072,20.1728 Z M11.52,0 C17.8944,0 23.04,5.1456 23.04,11.52 C23.04,17.8944 17.8944,23.04 11.52,23.04 C5.1456,23.04 0,17.8944 0,11.52 C0,5.1456 5.1456,0 11.52,0 Z M11.52,9.8816 C12.544,9.8816 13.3888,10.7264 13.3888,11.7504 C13.3888,12.7744 12.544,13.6192 11.52,13.6192 C10.496,13.6192 9.6512,12.8 9.6512,11.7504 C9.6512,10.7264 10.496,9.8816 11.52,9.8816 Z" />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default SafetyFeatureFallbackIcon;
