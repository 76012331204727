import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Hidden from '@mui/material/Hidden';
import _ from 'lodash';
import Link from 'next/link';
import PropTypes from 'prop-types';
import React from 'react';

import { LINKS } from '../../constants';

import { ANCAPLogo, RedbookLogo, TACLogo, UCSRCombinedLogo, VicLogo } from './logos';
import {
  ContentWrapper,
  FooterLink,
  FooterLinks,
  FooterWrapper,
  FocusA,
  LinksLabel,
  StyledLink,
  List,
  ListLink,
  Logos,
  LogoWrapper,
  LogoListItem,
  Pipe,
  SocialLink,
  StyledIcon,
  TermItem,
} from './styles';

const Footer = ({ termsAndPrivacyLinks = null, socialLinks = null, pageLinks = null }) => {
  const pageLinksGrouped = _.chunk(pageLinks, 2);

  return (
    <FooterWrapper>
      <ContentWrapper>
        <Container maxWidth="xl">
          <Grid container>
            <Grid item xs={12} sm={6}>
              {/* @ts-expect-error [🤖 PLEASE FIX 🤖]: Type '{ children: string; role: "heading"; "aria-level": string; }' is not assignable to type 'HTMLAttributes<HTMLParagraphElement>'. */}
              <LinksLabel role="heading" aria-level="2">
                Join the conversation
              </LinksLabel>
              <List data-testid="social-links-list">
                {socialLinks &&
                  _.map(socialLinks, (link, index) => (
                    <SocialLink key={index}>
                      {/* @ts-expect-error [🤖 PLEASE FIX 🤖]: Property 'url' does not exist on type 'never'. */}
                      <FocusA href={link.url} target="_blank" rel="noopener noreferrer">
                        {/* @ts-expect-error [🤖 PLEASE FIX 🤖]: Property 'iconName' does not exist on type 'never'. */}
                        <StyledIcon name={link.iconName} />
                      </FocusA>
                    </SocialLink>
                  ))}
              </List>
              <Logos>
                <LogoListItem>
                  <LogoWrapper href={LINKS.TAC} target="_blank" rel="noopener noreferrer">
                    <TACLogo />
                  </LogoWrapper>
                </LogoListItem>
                <LogoListItem>
                  {/* @ts-expect-error [🤖 PLEASE FIX 🤖]: Type '{ children: Element; href: string; target: "_blank"; rel: string; isShortened: true; }' is not assignable to type 'IntrinsicAttributes & { theme?: Theme | undefined; as?: ElementType<any, keyof IntrinsicElements> | undefined; } & ClassAttributes<...> & AnchorHTMLAttributes<...> & { ...; }'. */}
                  <LogoWrapper href={LINKS.VICTORIA_GOVERNMENT} target="_blank" rel="noopener noreferrer" isShortened>
                    <VicLogo />
                  </LogoWrapper>
                </LogoListItem>
                <LogoListItem>
                  <LogoWrapper href={LINKS.ANCAP} target="_blank" rel="noopener noreferrer">
                    <ANCAPLogo />
                  </LogoWrapper>
                </LogoListItem>
                <LogoListItem>
                  <LogoWrapper target="_blank" rel="noopener noreferrer">
                    <UCSRCombinedLogo />
                  </LogoWrapper>
                </LogoListItem>
                <LogoListItem>
                  {/* @ts-expect-error [🤖 PLEASE FIX 🤖]: Type '{ children: Element; target: "_blank"; rel: string; isShortened: true; }' is not assignable to type 'IntrinsicAttributes & { theme?: Theme | undefined; as?: ElementType<any, keyof IntrinsicElements> | undefined; } & ClassAttributes<...> & AnchorHTMLAttributes<...> & { ...; }'. */}
                  <LogoWrapper target="_blank" rel="noopener noreferrer" isShortened>
                    <RedbookLogo />
                  </LogoWrapper>
                </LogoListItem>
              </Logos>
            </Grid>

            <Hidden smDown>
              <Grid item sm={6}>
                {/* @ts-expect-error [🤖 PLEASE FIX 🤖]: Type '{ children: string; role: "heading"; "aria-level": string; }' is not assignable to type 'HTMLAttributes<HTMLParagraphElement>'. */}
                <LinksLabel role="heading" aria-level="2">
                  More about
                </LinksLabel>
                {pageLinks && (
                  <ListLink>
                    {_.map(pageLinksGrouped, (links) =>
                      _.map(links, (link, i) => (
                        <FooterLink key={i}>
                          {/* @ts-expect-error [🤖 PLEASE FIX 🤖]: 'link' is of type 'unknown'. */}
                          <StyledLink href={link.url}>{link.title}</StyledLink>
                        </FooterLink>
                      ))
                    )}
                  </ListLink>
                )}
              </Grid>
            </Hidden>

            {termsAndPrivacyLinks && (
              <ListLink>
                {_.map(termsAndPrivacyLinks, (link, index) => (
                  <TermItem key={index}>
                    <FooterLinks>
                      {/* @ts-expect-error [🤖 PLEASE FIX 🤖]: Property 'url' does not exist on type 'never'. */}
                      <Link href={link.url}>{link.title}</Link>
                      {index + 1 < _.size(termsAndPrivacyLinks) ? <Pipe> | </Pipe> : null}
                    </FooterLinks>
                  </TermItem>
                ))}
              </ListLink>
            )}
          </Grid>
        </Container>
      </ContentWrapper>
    </FooterWrapper>
  );
};

Footer.propTypes = {
  termsAndPrivacyLinks: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
      openInNewWindow: PropTypes.bool,
    })
  ),
  socialLinks: PropTypes.arrayOf(
    PropTypes.shape({
      iconName: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
    })
  ),
  pageLinks: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
      openInNewWindow: PropTypes.bool,
    })
  ),
};

export default Footer;
