import React from 'react';

import buildKeysObject from '@/utils/build-keys-object';
import { iconsWithBackgrounds } from '@/components/icon-with-background';

import { COLORS } from '.';

const ANCAP_TEST_CATEGORY_IDENTIFIERS = {
  ACTIVE: 'safety-assist',
  ADULTS: 'adult-occupant-protection',
  KIDS: 'child-occupant-protection',
  PEDESTRIANS: 'pedestrian-protection',
  VRU: 'vulnerable-road-user-protection',
};

const TAC_SAFETY_FEATURE_CATEGORY_IDENTIFIERS = {
  ACTIVE: 'active-safety-features',
  ADULTS: 'crash-protection-for-adults',
  KIDS: 'crash-protection-for-kids',
  PEDESTRIANS: 'crash-protection-for-other-road-users',
  VRU: 'crash-protection-for-other-road-users',
};

const SAFETY_FEATURE_STATUSES = {
  STANDARD: {
    labelText: 'Included',
    Icon: iconsWithBackgrounds.GreenTick,
    labelColor: COLORS.SUCCESS_GREEN_NINE,
    databaseKey: 'standard',
  },
  NOT_INCLUDED: {
    labelText: 'Not Included',
    Icon: iconsWithBackgrounds.RedCross,
    labelColor: COLORS.DANGER_RED_EIGHT,
  },
  OPTIONAL: {
    labelText: 'Optional',
    Icon: iconsWithBackgrounds.GreyPlus,
    labelColor: COLORS.GREY_EIGHT,
    databaseKey: 'optional',
  },
  UNKNOWN: {
    labelText: 'Unknown',
    Icon: iconsWithBackgrounds.GreyQuestionMark,
    labelColor: COLORS.GREY_EIGHT,
  },
};

const SAFETY_FEATURE_STATUS_KEYS = buildKeysObject(SAFETY_FEATURE_STATUSES);

const P_PLATE_RESTRICTION_STATUSES = {
  REFERRED: {
    icon: iconsWithBackgrounds.GreyQuestionMark,
    status: 'Under Review',
  },
  BANNED: {
    icon: iconsWithBackgrounds.RedCross,
    status: 'Banned',
  },
  APPROVED: {
    icon: iconsWithBackgrounds.GreenTick,
    status: 'Approved',
  },
};

const P_PLATE_RESTRICTION_STATUS_KEYS = buildKeysObject(P_PLATE_RESTRICTION_STATUSES);

const ALERT_STATUSES = {
  success: {
    icon: iconsWithBackgrounds.GreenTick,
    backgroundColor: COLORS.SUCCESS_GREEN_TWO,
    statusText: 'Success',
  },
  error: {
    icon: iconsWithBackgrounds.RedWarning,
    backgroundColor: COLORS.DANGER_RED_ONE,
    statusText: 'Error',
  },
  saferPick: {
    icon: iconsWithBackgrounds.BlueTick,
    backgroundColor: COLORS.BRAND_NAVY_ONE,
    statusContent: (
      <>
        Used Car Safety Rating <strong>Safer Pick</strong>
      </>
    ),
    statusText: 'Safer Pick',
  },
};

export {
  ALERT_STATUSES,
  SAFETY_FEATURE_STATUSES,
  SAFETY_FEATURE_STATUS_KEYS,
  P_PLATE_RESTRICTION_STATUSES,
  P_PLATE_RESTRICTION_STATUS_KEYS,
  ANCAP_TEST_CATEGORY_IDENTIFIERS,
  TAC_SAFETY_FEATURE_CATEGORY_IDENTIFIERS,
};
