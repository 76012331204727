// @ts-expect-error [🤖 PLEASE FIX 🤖]: Could not find a declaration file for module 'react-google-recaptcha'. '/Users/aaronpierce/workspace/tac-hsiyc-web/node_modules/react-google-recaptcha/lib/index.js' implicitly has an 'any' type.
import ReCAPTCHA from 'react-google-recaptcha';
import React from 'react';
import styled from '@emotion/styled';

import muiTheme from '../../constants/mui-theme';
import { ExtraSmall } from '../type';

const HelperText = styled(ExtraSmall)`
  margin: 8px 14px 0;

  ${(props) =>
    // @ts-expect-error [🤖 PLEASE FIX 🤖]: Property 'error' does not exist on type '{ theme?: Theme | undefined; as?: ElementType<any, keyof IntrinsicElements> | undefined; } & ClassAttributes<HTMLParagraphElement> & HTMLAttributes<...> & { ...; } & { ...; }'. Did you mean 'onError'?
    props.error &&
    `
    color: ${muiTheme.palette.error.main};
  `}
`;

// @ts-expect-error [🤖 PLEASE FIX 🤖]: Binding element 'error' implicitly has an 'any' type.
const Recaptcha = ({ error, helperText, ...props }) => {
  return (
    <>
      <ReCAPTCHA {...props} />
      {/* @ts-expect-error [🤖 PLEASE FIX 🤖]: Type '{ children: any; error: any; }' is not assignable to type 'IntrinsicAttributes & { theme?: Theme | undefined; as?: ElementType<any, keyof IntrinsicElements> | undefined; } & ClassAttributes<...> & HTMLAttributes<...> & { ...; }'. */}
      <HelperText error={error}>{helperText}</HelperText>
    </>
  );
};

export default Recaptcha;
