import _ from 'lodash';
import { getVehicleImages } from '@/utils/get-vehicle-image';
import { cutOutDuplicateMakeModels } from '@/components/similar-but-safer/utils/cut-out-duplicate-make-models';
import { cutOutMatchingMakeModels } from '@/components/similar-but-safer/utils/cut-out-matching-make-models';

// @ts-expect-error [🤖 PLEASE FIX 🤖]: Binding element 'hits' implicitly has an 'any' type.
export const filterRawAlgoliaHits = ({ hits, makeDescription, modelDescription, year }) => {
  let vehicles = _.map(hits, (hit) => ({
    ...hit,
    newPrice: hit.newPrice,
    priceRange: { low: hit.privateMinPrice, high: hit.privateMaxPrice },
    image: hit.image || undefined,
  }));

  vehicles = _.filter(vehicles, ({ maxPrice, minPrice }) => maxPrice > 0 && minPrice > 0);

  vehicles = cutOutDuplicateMakeModels(vehicles);
  vehicles = cutOutMatchingMakeModels(vehicles, makeDescription, modelDescription, year);
  vehicles = getVehicleImages(vehicles);

  return vehicles;
};
