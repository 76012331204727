import React from 'react';

// @ts-expect-error [🤖 PLEASE FIX 🤖]: Parameter 'props' implicitly has an 'any' type.
const Visibility = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" width="32" height="32" {...props}>
    <path d="M16,6 C9.33333333,6 3.64,10.1466667 1.33333333,16 C3.64,21.8533333 9.33333333,26 16,26 C22.6666667,26 28.36,21.8533333 30.6666667,16 C28.36,10.1466667 22.6666667,6 16,6 Z M16,22.6666667 C12.32,22.6666667 9.33333333,19.68 9.33333333,16 C9.33333333,12.32 12.32,9.33333333 16,9.33333333 C19.68,9.33333333 22.6666667,12.32 22.6666667,16 C22.6666667,19.68 19.68,22.6666667 16,22.6666667 Z M16,12 C13.7866667,12 12,13.7866667 12,16 C12,18.2133333 13.7866667,20 16,20 C18.2133333,20 20,18.2133333 20,16 C20,13.7866667 18.2133333,12 16,12 Z" />
  </svg>
);

export default Visibility;
