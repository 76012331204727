import React from 'react';

// @ts-expect-error [🤖 PLEASE FIX 🤖]: Parameter 'props' implicitly has an 'any' type.
const TwitterIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="22"
    height="19"
    viewBox="0 0 22 19"
    aria-labelledby="twitter-logo"
    role="img"
    {...props}
  >
    <title id="twitter-logo">Twitter</title>
    <path d="M21.393.783a8.874 8.874 0 0 1-2.868 1.122A4.452 4.452 0 0 0 15.23.445c-2.49 0-4.512 2.072-4.512 4.628 0 .363.039.714.116 1.053-3.75-.193-7.076-2.033-9.304-4.837a4.712 4.712 0 0 0-.61 2.33c0 1.605.796 3.021 2.008 3.851a4.431 4.431 0 0 1-2.046-.576v.056c0 2.243 1.556 4.115 3.622 4.538a4.398 4.398 0 0 1-2.039.081c.575 1.838 2.24 3.177 4.216 3.213A8.913 8.913 0 0 1 0 16.7a12.564 12.564 0 0 0 6.919 2.078c8.303 0 12.842-7.051 12.842-13.168 0-.201-.004-.402-.011-.6A9.26 9.26 0 0 0 22 2.615a8.84 8.84 0 0 1-2.592.728 4.62 4.62 0 0 0 1.985-2.56" />
  </svg>
);

export default TwitterIcon;
