import _ from 'lodash';

// @ts-expect-error [🤖 PLEASE FIX 🤖]: Parameter 'state' implicitly has an 'any' type.
const addToCompare = (state, vehicleId) => {
  if (_.includes(state.compareVehicleIds, vehicleId)) {
    return state;
  }

  return _.assign({}, state, {
    compareVehicleIds: _.concat(state.compareVehicleIds, vehicleId),
  });
};

// @ts-expect-error [🤖 PLEASE FIX 🤖]: Parameter 'state' implicitly has an 'any' type.
const removeFromCompare = (state, vehicleId) => {
  return _.assign({}, state, {
    compareVehicleIds: _.without(state.compareVehicleIds, vehicleId),
  });
};

export default { addToCompare, removeFromCompare };
