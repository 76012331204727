import { convertToCurrencyFormat } from '../convert-to-currency-format';

interface PriceParams {
  newPrice?: number | null;
  privateMinPrice?: number | null;
  privateMaxPrice?: number | null;
}

export const getRelevantPriceForVehicle = ({ newPrice, privateMinPrice, privateMaxPrice }: PriceParams): string => {
  if (privateMinPrice && privateMaxPrice) {
    return `$${convertToCurrencyFormat(privateMinPrice)} - $${convertToCurrencyFormat(privateMaxPrice)}`;
  }

  if (newPrice) {
    return `$${convertToCurrencyFormat(newPrice)}`;
  }

  return 'N/A';
};

export const getPriceGuideCopy = ({ newPrice, privateMinPrice, privateMaxPrice }: PriceParams) => {
  if (privateMinPrice && privateMaxPrice) {
    return `From ${getRelevantPriceForVehicle({ newPrice, privateMinPrice, privateMaxPrice })}`;
  }

  if (newPrice) {
    return `Price Guide: ${getRelevantPriceForVehicle({ newPrice, privateMinPrice, privateMaxPrice })}`;
  }

  return 'Pricing unavailable';
};
