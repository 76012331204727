import _ from 'lodash';
import MuiFormControl from '@mui/material/FormControl';
import MuiFormHelperText from '@mui/material/FormHelperText';
import MuiInputLabel from '@mui/material/InputLabel';
import MuiMenuItem from '@mui/material/MenuItem';
import MuiSelect from '@mui/material/Select';
import OutlinedInput from '@mui/material/OutlinedInput';
import PropTypes from 'prop-types';
import React from 'react';
import styled from '@emotion/styled';
import { styled as muiStyled } from '@mui/system';

import Icon, { iconNames } from '../icons';
import { BREAKPOINTS, BOX_SHADOW, COLORS } from '../../constants';

import { fieldStyles, formTheme } from './field-styles';

const StyledIcon = styled(Icon)`
  fill: ${formTheme.palette.secondary.main};
  height: auto;
  pointer-events: none;
  position: absolute;
  right: 18px;
  top: calc(50% - 5px);
  width: 12px;

  @media (min-width: ${BREAKPOINTS.SM}px) {
    top: calc(50% - 8px);
    width: 18px;
  }

  @media (min-width: ${BREAKPOINTS.LG}px) {
    top: calc(50% - 10px);
    width: 20px;
  }
`;

const Wrapper = styled.div`
  .MuiInputBase-root {
    ${fieldStyles.root}
  }

  .MuiOutlinedInput-inputSelect {
    padding-right: 46px;
    color: ${
      /* @ts-expect-error [🤖 PLEASE FIX 🤖]: Property 'light' does not exist on type '{ main: string; dark: string; }'. */
      formTheme.palette.secondary.light
    };
  }

  .MuiInputLabel-outlined {
    color: ${formTheme.palette.secondary.main};
  }

  .MuiSelect-select:focus {
    background-color: transparent;
  }

  .MuiOutlinedInput-notchedOutline {
    border-color: ${formTheme.palette.secondary.main};
  }

  .MuiOutlinedInput-root:hover {
    .MuiOutlinedInput-notchedOutline {
      border-color: ${
        /* @ts-expect-error [🤖 PLEASE FIX 🤖]: Property 'light' does not exist on type '{ main: string; dark: string; }'. */
        formTheme.palette.secondary.light
      };
    }

    ${StyledIcon} {
      fill: ${
        /* @ts-expect-error [🤖 PLEASE FIX 🤖]: Property 'light' does not exist on type '{ main: string; dark: string; }'. */
        formTheme.palette.secondary.light
      };
    }
  }

  .MuiOutlinedInput-root.Mui-focused.Mui-focused {
    ${StyledIcon} {
      fill: ${formTheme.palette.primary.main};
    }

    .MuiOutlinedInput-notchedOutline {
      border-color: ${formTheme.palette.primary.main};
    }

    &:hover .MuiOutlinedInput-notchedOutline {
      border-color: ${formTheme.palette.primary.main};
    }
  }

  .MuiFormLabel-root.Mui-focused {
    color: ${formTheme.palette.primary.main};
  }

  .MuiFormHelperText-root {
    ${fieldStyles.helperText}
    color: ${formTheme.palette.secondary.main};
  }
`;

const StyledFormControl = styled(MuiFormControl)`
  ${fieldStyles.outline}

  .MuiFormLabel-root {
    ${fieldStyles.root}
    background:white;
    padding-left: 5px;
    padding-right: 5px;
  }

  width: 100%;
`;

const StyledMenuItem = styled(MuiMenuItem)`
  font-size: ${formTheme.typography.menu.fontSize} !important;
`;

// @ts-expect-error [🤖 PLEASE FIX 🤖]: Parameter 'props' implicitly has an 'any' type.
const Select = (props) => {
  const [value, setValue] = React.useState('');
  return <ControlledSelect value={value} onChange={setValue} {...props} />;
};

const ControlledSelect = ({
  children = null,
  // @ts-expect-error [🤖 PLEASE FIX 🤖]: Binding element 'value' implicitly has an 'any' type.
  value,
  id = null,
  // @ts-expect-error [🤖 PLEASE FIX 🤖]: Binding element 'onChange' implicitly has an 'any' type.
  onChange,
  label = null,
  options = null,
  error = false,
  helperText = null,
}) => {
  const PopoverPaper = muiStyled('div')(({ theme }) => ({
    ...theme.components?.MuiPopover?.styleOverrides?.paper, // Ensure original MUI paper styles are included
    // @ts-expect-error [🤖 PLEASE FIX 🤖]: Property 'BRAND_GREY_SEVEN' does not exist on type '{ WHITE: string; BLACK: string; BRAND_BLUE_ONE: string; BRAND_BLUE_TWO: string; BRAND_BLUE_THREE: string; BRAND_BLUE_FOUR: string; BRAND_BLUE_FIVE: string; BRAND_BLUE_SIX: string; BRAND_BLUE_SEVEN: string; ... 59 more ...; SUCCESS_GREEN_ELEVEN: string; }'. Did you mean 'BRAND_BLUE_SEVEN'?
    border: `1px solid ${COLORS.BRAND_GREY_SEVEN}`,
    borderRadius: 0,
    boxShadow: BOX_SHADOW.DEFAULT,
  }));

  const inputLabelRef = React.useRef(null);
  const [labelWidth, setLabelWidth] = React.useState(0);
  React.useEffect(() => {
    // @ts-expect-error [🤖 PLEASE FIX 🤖]: Property 'offsetWidth' does not exist on type 'never'.
    setLabelWidth(inputLabelRef?.current?.offsetWidth * 1.2);
  }, []);

  if (_.isEmpty(options) && _.isNil(children)) {
    return null;
  }

  // @ts-expect-error [🤖 PLEASE FIX 🤖]: Argument of type 'null' is not assignable to parameter of type 'string | undefined'.
  const transformedLabel = _.kebabCase(label);

  return (
    <Wrapper>
      <StyledFormControl variant="outlined" error={error}>
        {/* @ts-expect-error [🤖 PLEASE FIX 🤖]: Argument of type 'null' is not assignable to parameter of type 'string | undefined'. */}
        <MuiInputLabel id={_.kebabCase(label)} ref={inputLabelRef} htmlFor={transformedLabel}>
          {label}
        </MuiInputLabel>
        <MuiSelect
          value={value}
          onChange={(event) => onChange(event.target.value)}
          // @ts-expect-error [🤖 PLEASE FIX 🤖]: Type '{ labelWidth: number; name: string; id: string; }' is not assignable to type 'IntrinsicAttributes & OutlinedInputProps'.
          input={<OutlinedInput labelWidth={labelWidth} name={transformedLabel} id={id || transformedLabel} />}
          IconComponent={() => <StyledIcon name={iconNames.caret} />}
          // @ts-expect-error [🤖 PLEASE FIX 🤖]: Argument of type 'null' is not assignable to parameter of type 'string | undefined'.
          labelId={_.kebabCase(label)}
          MenuProps={{
            elevation: 0,
            PopoverClasses: {
              // @ts-expect-error [🤖 PLEASE FIX 🤖]: Type 'StyledComponent<MUIStyledCommonProps<Theme>, DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>, {}>' is not assignable to type 'string'.
              paper: PopoverPaper,
            },
          }}
        >
          {/* Default to children prop and then to options */}
          {children ||
            _.map(options, (option) => (
              // @ts-expect-error [🤖 PLEASE FIX 🤖]: 'option' is of type 'unknown'.
              <StyledMenuItem key={option.value} value={option.value}>
                {/* @ts-expect-error [🤖 PLEASE FIX 🤖]: 'option' is of type 'unknown'. */}
                {option.label}
              </StyledMenuItem>
            ))}
        </MuiSelect>
        {helperText && <MuiFormHelperText>{helperText}</MuiFormHelperText>}
      </StyledFormControl>
    </Wrapper>
  );
};

ControlledSelect.propTypes = {
  id: PropTypes.string,
  children: PropTypes.node,
  label: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
    })
  ),
  error: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  onChange: PropTypes.func.isRequired,
  helperText: PropTypes.string,
};

ControlledSelect.MenuItem = StyledMenuItem;

export default Select;

export { ControlledSelect };
